<template>
  <v-app id="404" class="bg-404">
      <v-container fluid fill-height>
        <v-layout align-center justify-center >
          <div class="text-md-center ">
            <div>
              <v-btn class="btnBack"  rounded @click="goHome">Regresar </v-btn>
            </div>  
          </div>
        </v-layout>
      </v-container>
  </v-app>
</template>

<script>
export default {
  methods: {
    goHome () {
      this.$router.push({ path: '/dashboard' });
    }
  }  
};
</script>
<style scoped lang="css">
  h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;    
  }
  .bg-404{
    background-image: url("/static/bg/404-small.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
  .btnBack.theme--light.v-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 10px 23px 10px 23px !important;
    color: #FFFFFF;
    text-transform: capitalize;
    height: 32px !important;
    min-width: 95px !important;
    background-color: #1E2245!important;
    margin-top:160px;
    }
  @media (min-width: 1367px) {
    .bg-404{
      background-image: url("/static/bg/404.png");
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
    }
    .btnBack.theme--light.v-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 23px 10px 23px !important;
    color: #FFFFFF;
    text-transform: capitalize;
    height: 48px !important;
    min-width: 150px !important;
    background-color: #1E2245!important;
    margin-top:250px;
    }
  }
  
</style>
