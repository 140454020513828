<template id="table-resultados">
    <simple-table
        :rows="rows"
        :columns="columns"
        :perPage="[10, 25, 50, 100]"
        :classes="tableClasses"
    >
        <tbody slot="body" slot-scope="{ data }">
            <tr :key="item.id" v-for="item in data">
                <template v-if="tab == 'empleados'">
                    <td>
                        <div>{{ item.clave }}</div>
                    </td>
                    <td @click="detalleEmpleado(item)" class="cursor-pointer">
                        <div>
                            <v-list color="transparent" class="py-0 my-0">
                                <v-list-item two-line class="py-0 my-0 px-0">
                                    <v-list-item-avatar class="py-0 my-0">
                                        <v-img  :src="urlImg+item.foto"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-0 py-0 my-0">
                                        <v-list-item-title class="tblPrincipal">{{ item.nombre_completo }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </td>
                    <td><div class="tblPrincipal">{{ item.antiguedad }}</div></td>
                    <td >
                        <div class="d-flex align-center justify-center">
                            <v-rating
                                v-model="item.objRankig.length"
                                size="24"
                                dense
                            >
                                <template v-slot:item="props">
                                    <v-tooltip bottom v-if="props.isFilled">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                                class="mx-1"
                                            >
                                                {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                                            </v-icon>
                                        </template>
                                        <span v-if="props.isFilled">{{item.objRankig[props.index]}}</span>
                                    </v-tooltip>

                                    <v-icon
                                        v-else
                                        :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                        class="mx-1"
                                    >
                                        {{ props.isFilled ? 'mdi-star' : 'mdi-star' }}
                                    </v-icon>
                                </template>
                            </v-rating>
                        </div>
                    </td>
                    <td>{{ item.nombre_departamento }}</td>
                </template>

                <template v-if="tab == 'diasFestivos'">
                    <td v-if="rol != 'admin-sucursal' && rol != 'admin-depto'" >
                        <div v-if="rol == 'root'">
                            {{ item.nombre_cliente }}
                        </div>
                        <div v-if="rol == 'root'" class="tblSecundario">
                            {{ item.nombre_empresa }}
                        </div>

                        <div v-if="rol == 'admin'" class="tblPrincipal">
                            {{ item.nombre_empresa }}
                        </div>
                        <div v-if="rol == 'admin'" class="tblSecundario">
                            {{ item.nombre_sucursal }}
                        </div>

                        <div v-if="rol == 'admin-empresa'" class="tblPrincipal">
                            {{ item.nombre }}
                        </div>
                    </td>

                    <td>
                        <div class="tblPrincipal">{{ item.descripcion }}</div>
                    </td>
                    <td>
                        <div class="tblPrincipal">{{ dateFormat(item.fecha) }}</div>
                    </td>
                </template>

                <template v-if="tab == 'horarios'">
                    <td v-if="rol == 'root'" class="px-1">
                        <div class="tblPrincipal">{{ item.nombre_empresa }}</div>
                    </td>

                    <td v-if="rol == 'admin'">
                        <div class="tblPrincipal">{{ item.nombre_empresa }}</div>
                    </td>
                    <td v-if="rol == 'admin'">
                        <div class="tblPrincipal">{{ item.nombre_sucursal }}</div>
                    </td>

                    <td v-if="rol == 'admin-empresa'">
                        <div class="tblPrincipal">{{ item.nombre_sucursal }}</div>
                    </td>

                    <td v-if="rol == 'admin-sucursal'">
                        <div class="tblPrincipal">{{ item.nombre }}</div>
                    </td>
                    <td v-if="rol != 'admin-sucursal'">
                        <div class="tblPrincipal">{{ item.nombre }}</div>
                    </td>

                    <td>
                        <div class="tblPrincipal">{{ item.descripcion }}</div>
                    </td>

                    <td v-if="rol == 'admin-depto'">
                        <div class="tblPrincipal">{{ item.minutos_tolerancia }}</div>
                    </td>
                </template>

                <template v-if="tab == 'incidencias'">
                    <td v-if="rol != 'empleado'">
                        <div>
                            <v-list color="transparent" class="py-0 my-0">
                                <v-list-item two-line class="py-0 my-0 px-0">
                                    <v-list-item-avatar class="py-0 my-0">
                                        <v-img  :src="urlImg+item.foto"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-0 py-0 my-0">
                                        <v-list-item-title class="tblPrincipal">{{ item.nombre_completo }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </td>

                    <td v-if="rol != 'empleado'" class="pl-0 trIncidencias" style="text-align: center !important;">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-chip class="vChip" :color="item.color" text-color="#000" v-on="on">
                                    {{ item.nombre_tipo.length < 12 ? item.nombre_tipo : item.nombre_tipo.substr(0, 11) + "..." }}
                                </v-chip>
                            </template>
                            <span>{{ item.nombre_tipo }}</span>
                        </v-tooltip>
                        </td>
                        <td v-else class="pl-3 trIncidencias" style="text-align: center !important;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-chip class="vChip" :color="item.color" text-color="#000" v-on="on">
                                        {{ item.nombre_tipo.length < 12 ? item.nombre_tipo : item.nombre_tipo.substr(0, 11) + "..." }}
                                    </v-chip>
                                </template>
                                <span>{{ item.nombre_tipo }}</span>
                            </v-tooltip>
                        </td>
                        <td><div class="tblPrincipal">{{ dateFormat(item.fecha_inicio) }}</div></td>
                        <td><div class="tblPrincipal">{{ dateFormat(item.fecha_final) }}</div></td>
                        <td class="tblPrincipal" v-if="rol == 'empleado'">{{ item.descripcion }}</td>

                        <td>
                            <v-chip class="mx-0 px-0 tipoLetra" color="transparent" outlined :text-color="getColorTextoStatus(item.valor_estatus)">
                                <img
                                    v-if="item.valor_estatus === 'estatus_aprobado'"
                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                    src="/static/icon/aprovado.png"
                                    alt="Empleado"
                                />
                                <img
                                    v-else-if="item.valor_estatus === 'estatus_rechazado'"
                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                    src="/static/icon/cancelado.png"
                                    alt="Empleado"
                                />
                                <img
                                    v-else-if="item.valor_estatus === 'estatus_pendiente'"
                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                    src="/static/icon/pendiente.png"
                                    alt="Empleado"
                                />
                                <img
                                    v-else
                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                    src="/static/icon/revision.png"
                                    alt="Empleado"
                                />
                                &nbsp;&nbsp;&nbsp; {{ item.nombre_estatus }}
                            </v-chip>
                        </td>
                </template>
            </tr>
        </tbody>
    </simple-table>
</template>

<script>
import urlImg from '../plugins/Urlimagen';
import Datatable from "@/components/simpletable/Datatable.vue";
import Star from '@/components/rating/Star.vue';

export default {
    components: {
        'rating-star' : Star,
        'simple-table': Datatable,
    },
    props: {
        tab: String,
        rol: String,
        columns: Array,
        rows: Array,
    },
    data() {
        return {
            urlImg   : urlImg.getURL(),
            months   : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            tableClasses: {
                "t-head" : {
                    "tamanio-head":true
                }
            },
        }
    },
    methods: {
        getColorTextoStatus(statusIncidencia){
            if (statusIncidencia == "estatus_aprobado") return "#15D511";
            if (statusIncidencia == "estatus_rechazado") return "#EE1616";
            if (statusIncidencia == "estatus_pendiente") return "#FF9B05";
            if (statusIncidencia == "estatus_revision") return "#F8690E";
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        detalleEmpleado(data) {
            this.$session.remove("detalleEmpleado");
            this.$session.set("detalleEmpleado", data);
            this.$router.push("/kardex/detalleEmpleado");
        },
    }
}
</script>

<style>
    .fechas{
        font-size: 70%;
        font-style: oblique;
    }

    .imagen_incidencia{
        text-align:-webkit-center !important;
        text-align: -moz-center !important;
        padding-top: 5%;
    }

    .vChip{
        width:200px;
        justify-content: center;
    }

    .vChip .v-chip .v-chip__content{
        font-family: Montserrat !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: -0.34px !important;
        line-height: 17px !important;
        width: 90px !important;
    }

    #chipIncidecias .v-chip{
        margin: 4px;
        height: 30px;
        white-space: nowrap !important;
    }

    .trIncidencias {
        width:200px;
    }

    .tipoLetra700Azul{
        color: #002438 !important;
        font-family: 'Montserrat';
        font-size: 14px !important;
        font-weight: 700 !important;
        letter-spacing: -0.34px !important;
        line-height: 17px !important;
        text-align: left !important;
    }

    .tipoLetra400Gris{
        color: #878EB0 !important;
        font-family: 'Montserrat';
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: -0.34px !important;
        line-height: 17px !important;
    }

    .tipoLetraFecha{
        color:#D3D7EB;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.43px;
        line-height: 22px;
        text-align: left;
    }

    .tipoLetra{
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.34px;
        line-height: 17px;
    }

    .tipoLetraNombre{
        color: #002438;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
    }

    .departamento{
        color: #878EB0;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.34px;
        line-height: 17px;
    }

    .transparente{
        background-color: transparent !important;
    }
    .cursor-pointer{
        cursor:pointer;
    }
</style>