<template>
    <div>
        <template v-for="i in maxValue">
            <template v-if="value >= i">
                <span :key="i" class="fa fa-star" :style="'color:' + color"></span>
            </template>

            <template v-else>
                <span :key="i" class="fa fa-star-o" :style="'color:' + colorEmpty"></span>
            </template>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: Number,
            default: 0,
            required: true,
        },
        maxValue: {
            type: Number,
            default: 5
        },
        color: {
            type: String,
            default: 'blue'
        },
        colorEmpty: {
            type: String,
            default: '#D3D7EB'
        }
    },
    data() {
        return {

        }
    },

}
</script>