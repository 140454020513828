import Vue from 'vue';

export default {
    getHorarios(parametros){
        return Vue.axios.get("horarios/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getHorario(id){
        return Vue.axios.get("horarios/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateHorario(parametros){
        return Vue.axios.put("horarios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteHorario(id){
        return Vue.axios.delete("horarios/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addHorario(parametros){
        return Vue.axios.post("horarios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}