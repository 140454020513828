<template>            
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">
        <v-col cols="12" xs="12" sm="12" md="12" >
            <v-divider class="my-7"></v-divider>
        </v-col>
        <v-col cols="12" xs="12" sm="8" md="8" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Nombre de la empresa" rules="required">
                <v-text-field
                    outlined
                    autofocus
                    label="Nombre de la empresa"
                    v-model="datosEmpresa.nombre"
                    :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                <v-text-field 
                    outlined
                    label="Clave" 
                    class="pa-0 ma-0" 
                    v-model="datosEmpresa.clave"
                    :error-messages="errors" 
                    required
                    id="txtClave"
                >
                </v-text-field>
            </ValidationProvider>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Nombre Comercial" rules="required">
                <v-text-field
                    outlined
                    label="Nombre Comercial" 
                    class="pa-0 ma-0" 
                    v-model="datosEmpresa.nombre_comercial" 
                    :error-messages="errors" 
                    required
                    id="txtComercial"
                >
                </v-text-field>
            </ValidationProvider>
        </v-col> -->
        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Razón social" rules="required">
                <v-text-field
                    outlined
                    label="Razón social"
                    v-model="datosEmpresa.razon_social"
                    required
                    :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Descripción del giro" rules="required">
                <v-text-field 
                    outlined
                    label="Descripción del giro" 
                    class="pa-0 ma-0" 
                    v-model="datosEmpresa.giro_descripcion" 
                    :error-messages="errors" 
                    required
                    id="txtDescripcion">
                </v-text-field>
            </ValidationProvider>
        </v-col> -->
        <v-col cols="12" xs="12" sm="6" md="6" class="py-0" >
            <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                <v-text-field
                    outlined
                    label="RFC"
                    v-model="datosEmpresa.rfc"
                    required
                    id="RFC"
                    maxlength="18"
                    :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
        </v-col>
         <v-col xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                <v-text-field
                    outlined
                    label="Dirección"
                    v-model="datosEmpresa.direccion"
                    required
                    :error-messages="errors"
                />
            </ValidationProvider>
        </v-col>
        <v-col xs="12" sm="3" md="3" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                <v-text-field
                    outlined
                    label="Código postal"
                    v-model="busca_cp"
                    :error-messages="errors"
                    @keyup.enter="busca_codigos()"
                    v-on:keydown.tab="busca_codigos()"
                    v-mask="'#####'"
                />
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="3" md="3" class="py-0">
            <v-text-field
                outlined
                label="Estado"
                v-model="estado"
                disabled
                class="pa-0 ma-0"
                id="txtEstado"
            >
            </v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="3" md="3" class="py-0">
            <v-text-field
                outlined
                label="Municipio"
                v-model="municipio"
                disabled
                class="pa-0 ma-0"
                id="txtMunicipio"
            >
            </v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="3" md="3" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                <v-autocomplete
                    outlined
                    label="Colonia"
                    :items="colonias"
                    item-text="nombre"
                    item-value="id"
                    persistent-hint
                    v-model="datosEmpresa.colonia_id"
                    class="pa-0 ma-0"
                    required 
                    :error-messages="errors"
                >
                </v-autocomplete>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Télefono" rules="required">
                <v-text-field
                    outlined
                    label="Teléfono" 
                    v-mask="'(###) ###-####'"
                    v-model="datosEmpresa.telefono_contacto" 
                    :error-messages="errors" 
                    required
                ></v-text-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
            <ValidationProvider v-slot="{ errors }" name="Contacto" rules="required">
                <v-text-field 
                    outlined
                    label="Contacto" 
                    class="pa-0 ma-0" 
                    v-model="datosEmpresa.nombre_contacto" 
                    :error-messages="errors" 
                    required
                    id="txtContacto"
                > 
                </v-text-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
            <ValidationProvider v-slot="{ errors }" name="Correo Contacto" rules="required|email" >
                <v-text-field 
                    outlined
                    label="Correo Contacto" 
                    class="pa-0 ma-0" 
                    v-model="datosEmpresa.correo_contacto" 
                    :error-messages="errors" 
                    required
                    id="txtCorreoContacto"
                >    
                </v-text-field>
            </ValidationProvider>
        </v-col>
       <!-- <v-col cols="12" xs="12" sm="12" md="12" >
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="pt-5">
            <p class="subtitulo">Datos Fiscales</p>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Representante legal" rules="required">
                <v-text-field
                    outlined
                    label="Representante legal"
                    v-model="datosEmpresa.representante_legal"
                    :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Método de pago" rules="required">
                <v-autocomplete
                    outlined
                    label="Método de pago"
                    :items="metodosPagos"
                    item-text="nombre"
                    item-value="id"
                    persistent-hint
                    v-model="datosEmpresa.metodo_de_pago_id"
                    class="pa-0 ma-0"
                    required
                    :error-messages="errors" 
                >
                </v-autocomplete>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <ValidationProvider v-slot="{ errors }" name="Banco" rules="required">
                <v-autocomplete
                    outlined
                    label="Banco"
                    :items="bancos"
                    item-text="nombre"
                    item-value="id"
                    persistent-hint
                    v-model="datosEmpresa.banco_empresa_f_id"
                    class="pa-0 ma-0"
                    required
                    :error-messages="errors" 
                >
                </v-autocomplete>
            </ValidationProvider>
        </v-col>
         <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
            <VuetifyDatePicker
                outlined
                v-model="inicio_operaciones"
                label="Inicio de operaciones"
                placeholder="dd/mm/aaaa"
                ref="FechaInicioOperaciones"
            />
        </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <VuetifyDatePicker
                    outlined
                    v-model="datosEmpresa.fecha_contrato"
                    label="Fecha de contrato"
                    placeholder="dd/mm/aaaa"
                    ref="FechaInicioContrato"
                />
        </v-col>-->
        </ValidationObserver>
    </v-row>
</template>
<script>

    import catalogosApi from "@/api/catalogos";
    import apiBancos from "@/api/nominas/bancos";
    import apiMetodoPago from "@/api/nominas/metodoPago";
    import Notify from "@/plugins/notify";
    import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

    export default {
    name:'pop-empresa',
    components:{
        VuetifyDatePicker
    },
    props:{
        oyente:Boolean
    },
    data(){
        return{
        rol:null,
        estado_id:null,
        datosLogin:null,
        datosEmpresa:{
            cliente_id:0,
            nombre:null,
            clave:null,
            nombre_comercial:null,
            razon_social:null,
            giro_descripcion:null,
            rfc:null,
            direccion:null,
            cp_id: null,
            estado_id :null,
            municipio_id : null,
            colonia_id : null,
            telefono_contacto : null,
            nombre_contacto:null,
            correo_contacto:null,
            tipo:'gdth',
            formValidate : false
        }, 
        busca_cp : "",
        estado : null,
        municipio : null,
        bancos : [],
        metodosPagos : [],
        colonias : [],
        fecha_contrato : false,
        inicio_operaciones : false
        }
    },
    props: {
        oyente: {
            type: Boolean,
            default: false
        },
    },
    watch:{
        oyente: function(val){          
        if(val){
            this.recopilaDatos();
        }
        },
        estado_id: function(val){
        this.cambioEstado(val);
        },
        busca_cp: function(val){
        if(val.length == 5){
            this.busca_codigos();
        }
        else{
            this.estado = "";
            this.municipio = "";
            this.colonias = [];
        }
        }
    },
    methods:{
        recopilaDatos(){
            this.$refs.form.validate().then(result => {
                if(result){                
                    this.datosEmpresa.formValidate = (this.datosEmpresa.formValidate) ? false : this.datosEmpresa.formValidate;            
                }
                else{
                    this.datosEmpresa.formValidate = true;
                }
                this.$emit("save",this.datosEmpresa);              
            });
        },
        resetValues(){
            this.datosEmpresa={
                cliente_id:0,
                nombre:null,
                clave:null,
                nombre_comercial:null,
                razon_social:null,
                giro_descripcion:null,
                rfc:null,
                direccion:null,
                cp_id: null,
                estado_id :null,
                municipio_id : null,
                colonia_id : null,
                telefono_contacto : null,
                nombre_contacto:null,
                correo_contacto:null,
                tipo:'nominas',
                formValidate : false
            }
        },
        getEstados(estado = null){
            let self = this;
            let parametros = {
                paginate:false,
                activo:true,
                order:'asc'
            }

            catalogosApi.getEstados(parametros).then((response) => {
                self.estados = response.data;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar los estados.");
                this.respuesta_error(err);
            });
        },
        cambioEstado(id, ciudad=null){
            this.datosEmpresa.ciudad_id = null;
            let parametros = { activo:true, paginate: false, estado_id: id, order: 'asc'}
            catalogosApi.getCiudades(parametros).then((response)=>{
                this.ciudades = response.data;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar las ciudades.");
                this.respuesta_error(err);
            });
        },
        respuesta_error(error){
            this.loading_guardar= false;
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogosApi.getCP(params).then((response) => {
                let result = response.data;

                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.datosEmpresa.estado_id = result[0].estado_id;
                this.datosEmpresa.ciudad_id = result[0].municipio_id;
                this.datosEmpresa.municipio_id = result[0].municipio_id;
                this.datosEmpresa.cp_id = result[0].id;

                catalogosApi.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                    return;
                });
            })
            .catch((e) => {
                Notify.ErrorToast("Ocurrió un error al cargar los estados y ciudades.");
                console.log(e);
            });
        },
        async getCP(id) {
            await catalogosApi.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                Notify.ErrorToast("Ocurrió un error al cargar los códigos postales.");
                console.error(err);
            });
        },
        getBancos(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiBancos.find(parametros).then((response) => {
                this.bancos = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Bancos.");
            });
        },
        getMetodosPagos(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiMetodoPago.find(parametros).then((response) => {
                this.metodosPagos = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Métodos de Pagos.");
            });
        },
    },
    created(){                    
        this.resetValues();
        this.getEstados();
        this.getBancos();
        this.getMetodosPagos();
    }
  }
</script>
<style scoped>
.subtitulo{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E2245;
}
.displayTemplate{
    display: contents;
  }
</style>