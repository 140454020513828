<template>
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">
            <v-col xs="12" sm="8" md="8" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                    <v-text-field
                        autofocus
                        id="primero"
                        outlined
                        label="Nombre"
                        v-model="horario.nombre"
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <v-col xs="12" sm="4" md="4" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Minutos tolerancia" rules="required">
                    <v-text-field
                        outlined
                        label="Minutos tolerancia"
                        v-model="horario.minutos_tolerancia"
                        v-mask="'####'"
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <v-col xs="12" sm="12" md="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                    <v-text-field
                        outlined
                        label="Descripción"
                        v-model="horario.descripcion"
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <template>
                <v-col xs="12" sm="12" md="12" class="py-0">
                    <ValidationProvider v-slot="{ errors }" name="Tipo jornada" rules="required">
                        <v-autocomplete
                            outlined
                            :items="jornadas"
                            item-text="descripcion"
                            item-value="id"
                            label="Tipo jornada"
                            persistent-hint
                            v-model="horario.jornada_id"
                            :error-messages="errors"
                        />
                    </ValidationProvider>
                </v-col>
            </template>
            <template v-if="rol == 'root' || rol == 'admin'">
                <v-col xs="12" sm="6" md="6" class="py-0">
                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                        <v-autocomplete
                            outlined
                            :items="empresas"
                            item-text="nombre"
                            item-value="id"
                            label="Empresa"
                            persistent-hint
                            v-model="empresa_value"
                            :error-messages="errors"
                        />
                    </ValidationProvider>
                </v-col>
            </template>
            <v-col xs="12" sm="12" md="12" class="text-center ma-0 pa-0">
                <h4 class="selDias">Selecciona los días</h4>
            </v-col>
            
            <div class="centrar">
                <v-checkbox
                    v-for="(d, index) in semanaHorarios"
                    :key="index"
                    hide-details
                    class="shrink mr-4 mt-0 dias"
                    v-model="dias_seleccionados"
                    :label="d.dia"
                    :value="d.dia"
                    :disabled="d.desabilitar"
                ></v-checkbox>
            </div>
            <v-layout row class="centrar mb-5">
                <v-col v-if="!navegador" xs="12" sm="5" md="5" class="">
                    <v-text-field
                        outlined
                        label="Hora entrada"
                        type="time"
                        v-model="hora_entrada"
                        id="hora_entrada"
                    ></v-text-field>
                </v-col>
                <v-col v-else xs="12" sm="5" md="5" class="">
                    <v-menu
                        ref="menu"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="10"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="hora_entrada"
                                label="Hora entrada"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu1"
                            v-model="hora_entrada"
                            full-widthformat="24hr"
                            scrollable
                            ampm-in-title
                            @click:minute="$refs.menu.save(hora_entrada)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-col v-if="!navegador" xs="12" sm="5" md="5" class="">
                    <v-text-field
                        outlined
                        label="Hora salida"
                        type="time"
                        v-model="hora_salida"
                        id="hora_salida"
                    ></v-text-field>
                </v-col>
                <v-col v-else xs="12" sm="5" md="5" class="">
                    <v-menu
                        ref="menuFin"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="hora_salida"
                                label="Hora salida"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu2"
                            v-model="hora_salida"
                            full-widthformat="24hr"
                            scrollable
                            ampm-in-title
                            @click:minute="$refs.menuFin.save(hora_salida)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-btn
                    color="#1E2245"
                    elevation="0"
                    class="mt-4"
                    dark
                    fab
                    @click="agregarHorario()"
                    small
                >
                    <v-icon dark>add</v-icon>
                </v-btn>
            </v-layout>
            <template class="pt-4 mt-4" v-for="(i, index) in horario.detalles_horario">
                <v-col col="12" xs="12" md="4" :key="index" >
                    <v-text-field
                        id="file_input_file"
                        label="Dia"
                        disabled
                        class="detalle"
                        type="text"
                        :value="i.dia"
                    ></v-text-field>
                </v-col>

                <v-col xs="12" sm="3" md="3" :key="index" >
                    <v-text-field
                        label="entrada"
                        type="text"
                        :value="i.hora_entrada"
                        disabled
                        class="detalle"
                    ></v-text-field>
                </v-col>
                <v-col xs="12" sm="3" md="3" :key="index" >
                    <v-text-field
                        label="salida"
                        type="text"
                        :value="i.hora_salida"
                        disabled
                        class="detalle"
                    ></v-text-field>
                </v-col>
                <v-btn
                    :key="index"
                    v-on="on"
                    class="botonHover"
                    icon
                    fab
                    dark
                    small
                    @click="eliminaHorario2(index, i.id)"
                >
                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                </v-btn>
            </template>
        </ValidationObserver>
    </v-row>
</template>

<script>
import catalogosApi from "@/api/catalogos";
import apiTipoJornada from "@/api/nominas/tipoJornada";
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import Notify from "@/plugins/notify";
export default {
    name: "pop-reloj",
    props:{
        oyente:Boolean,
        reset:Boolean,
    },
    data(){
        return{ 
            clientes: [],
            empresas: [],
            sucursales: [],                                   
            sucursales:[], 
            cliente_value: null,
            empresa_value: null,
            sucursal_value:null,

            horario:{
                id:0,
                nombre:"",
                descripcion:"",
                minutos_tolerancia:"",
                detalles_horario:[],
                detalles_eliminados:[],
                formValidate:false,
                status:'horarios',
                jornada_id:null,
                cliente_id: null,
                empresa_id: null,
                sucursal_id: null,
            },
            dias_seleccionados:[],
            semanaHorarios:[{dia:'Lunes', desabilitar: false}, {dia:'Martes', desabilitar: false}, {dia:'Miércoles', desabilitar: false}, {dia:'Jueves', desabilitar: false}, {dia:'Viernes', desabilitar: false}, {dia:'Sábado', desabilitar: false}, {dia:'Domingo', desabilitar: false}],
            semana:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
            semanaOriginal:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
            dia:"",
            hora_entrada:'',
            hora_salida:'',
            jornadas:[],
            menu1: false,
            menu2: false,
            navegador:false,                        
        }
    },
    watch:{
        oyente: function(val){
            if(val){
                this.recopilaDatos();
            }
        },
        reset: function(val){
            if(val){
                this.resetValues();
                this.loadData();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        },
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        empresa_value: function(val) {
            this.cambiaEmpresa(val);
        },

    },
    methods:{
        detectarNavegador(){
            var sBrowser, sUsrAg = navigator.userAgent;
                if(sUsrAg.indexOf("Chrome") > -1) {
                    sBrowser = "Google Chrome";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("Safari") > -1) {
                    sBrowser = "Apple Safari";
                    this.navegador = true;
                } else if (sUsrAg.indexOf("Opera") > -1) {
                    sBrowser = "Opera";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("Firefox") > -1) {
                    sBrowser = "Mozilla Firefox";
                    this.navegador = false;
                } else if (sUsrAg.indexOf("MSIE") > -1) {
                    sBrowser = "Microsoft Internet Explorer";
                    this.navegador = false;
                }
        }, 
        recopilaDatos(){
            this.$refs.form.validate().then(success => {
                this.horario.cliente_id =  this.cliente_value;
                this.horario.empresa_id = this.empresa_value;
                if(success){
                    this.horario.formValidate = (this.horario.formValidate) ? false : this.horario.formValidate;
                    this.horario.minutos_tolerancia = parseInt(this.horario.minutos_tolerancia, 10);                    
                }
                else{
                    this.horario.formValidate = true;
                }
                if(this.horario.detalles_horario.length == 0 ){
                    this.horario.formValidate = true;
                    Notify.ErrorToast("Es requerido agregar al menos un día con horario");
                }                       
                this.$emit("save",this.horario);                
            });            
        },
        loadData(){
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.horario.sucursal_id = this.datosLogin.sucursal_id;
            }
        },
        async getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            await clientesApi
                .getClientes(param)
                .then((response) => {
                this.clientes = response.data;
                })
                .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
                });
        },
        async getEmpresas(empresa = null) {
            let param = { activo: true, paginate: false };
            await empresaApi
                .getEmpresas(param)
                .then((response) => {
                    this.empresas = response.data;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
        },
        cambiaCliente(cliente, accion = null) {
            let param = { activo: true, paginate: false, cliente_id: cliente };
            empresaApi
                .getEmpresas(param)
                .then((response) => {
                    this.empresas = response.data;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
        },
        cambiaEmpresa(empresa, accion = null) {
            let parametros = { activo: true, paginate: false, empresa_id: empresa, order:'asc' };
            sucursalApi
                .getSucursales(parametros)
                .then((response) => {
                    this.sucursales = response.data;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading_guardar = false;
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                });
        },
        respuesta_error(error){
            this.loading_guardar= false;
            Swal.fire({
                position: 'center',
                type: 'error',
                title: "Error",
                text: error.response.data.message,
                showConfirmButton: true,
            }) 
        },
        resetValues(){
            this.horario={
                id:0,
                nombre:"",
                descripcion:"",
                minutos_tolerancia:"",
                detalles_horario:[],
                detalles_eliminados:[],
                formValidate:false,
                status:'horarios',
                jornada_id:null,
                cliente_id: null,
                empresa_id: null,
                sucursal_id: null,
            };
            this.cliente_value = null;
            this.empresa_value = null;
            this.clientes= [];
            this.empresas= [];
            this.sucursales= [];  

            this.dias_seleccionados=[];
            this.semanaHorarios=[{dia:'Lunes', desabilitar: false}, {dia:'Martes', desabilitar: false}, {dia:'Miércoles', desabilitar: false}, {dia:'Jueves', desabilitar: false}, {dia:'Viernes', desabilitar: false}, {dia:'Sábado', desabilitar: false}, {dia:'Domingo', desabilitar: false}];
            this.semana=['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
            this.semanaOriginal=['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
            this.dia="";
            this.hora_entrada='';
            this.hora_salida='';
            this.jornadas=[];
        },
        agregarHorario() {
            let self = this;
            if (this.dias_seleccionados != "" && this.hora_entrada != "" && this.hora_salida != "") {
                var hr_entrada = parseInt(this.hora_entrada.substring(0, 2));
                var min_entrada = parseInt(this.hora_entrada.substring(2, 4));
                var hr_salida = parseInt(this.hora_salida.substring(0, 2));
                var min_salida = parseInt(this.hora_salida.substring(2, 4));
                if (hr_entrada > 23 || min_entrada > 60 || hr_salida > 23 || min_salida > 60) {
                    Notify.Warning("Hora incorrecta", "La hora registrada es incorrecta" );
                } 
                else {
                    if (this.hora_salida <= this.hora_entrada) {
                        Notify.Warning("Hora incorrecta", "La hora de salida debe ser mayor a la hora de entrada. Formato de hora hh:mm, Ejemplos: 09:00 - 13:00" );
                    } 
                    else {
                        if (this.hora_entrada.length < 4 || this.hora_salida.length < 4) {
                            Notify.Warning("Formato de hora incorrecta", "Formato de hora hh:mm, Ejemplos: 09:00 - 13:00" );
                        } 
                        else {
                            this.hora_entrada =
                            this.hora_entrada.substring(0, 2) +
                            ":" +
                            this.hora_entrada.substring(3, 5) +
                            ":00";

                            this.hora_salida =
                            this.hora_salida.substring(0, 2) +
                            ":" +
                            this.hora_salida.substring(3, 5) +
                            ":00";
                            this.dias_seleccionados.forEach((element) => {
                                let detalle_horario = {
                                    id: 0,
                                    dia: element,
                                    hora_entrada: this.hora_entrada,
                                    hora_salida: this.hora_salida,
                                }; 
                                this.horario.detalles_horario.push(detalle_horario);
                                let ordenaDetalle_horario = [];
                                for (var i = 0; i < this.semanaOriginal.length; i++) {
                                    for (var j = 0; j < this.horario.detalles_horario.length;j++) {
                                        if (this.semanaOriginal[i] === this.horario.detalles_horario[j].dia) {
                                            ordenaDetalle_horario.push(
                                                this.horario.detalles_horario[j]
                                            );
                                        }
                                    }
                                }
                                this.horario.detalles_horario = ordenaDetalle_horario;
                            });
                            this.horario.detalles_horario.forEach((element) => {
                                this.semanaHorarios.forEach((el) => {
                                    if (element.dia == el.dia) {
                                    el.desabilitar = true;
                                    }
                                });
                            });
                            this.dias_seleccionados = [];
                            this.hora_entrada = "";
                            this.hora_salida = "";
                        }
                    }
                }
            }
            else{
                if(this.dias_seleccionados.length == 0){
                    Notify.Warning("Seleccionar días", "Seleccionar al menos un día." );
                }
                else if( this.hora_entrada == "" || this.hora_salida == ""){
                    Notify.Warning("Formato hora", "Ingresar un horario correcto. Ejemplo: 12:30." );
                }                
            }
        },
        agregarDia(para_p) {
            if (this.dia != "" && this.hora_entrada != "" && this.hora_salida != "") {
            var hr_entrada = parseInt(this.hora_entrada.substring(0, 2));
            var min_entrada = parseInt(this.hora_entrada.substring(2, 4));
            var seg_entrada = parseInt(this.hora_entrada.substring(4, 6));
            var hr_salida = parseInt(this.hora_salida.substring(0, 2));
            var min_salida = parseInt(this.hora_salida.substring(2, 4));
            var seg_salida = parseInt(this.hora_salida.substring(4, 6));
            if (
                hr_entrada > 23 ||
                min_entrada > 60 ||
                seg_entrada > 60 ||
                hr_salida > 23 ||
                min_salida > 60 ||
                seg_salida > 60
            ) {
                Swal.fire({
                type: "info",
                title: "Hora erronea",
                text: "La hora registrada es incorrecta",
                });
            } else {
                this.hora_entrada =
                this.hora_entrada.substring(0, 2) +
                +this.hora_entrada.substring(2, 4) +
                ":" +
                this.hora_entrada.substring(4, 6);
                this.hora_salida =
                this.hora_salida.substring(0, 2) +
                ":" +
                this.hora_salida.substring(2, 4) +
                ":" +
                this.hora_salida.substring(4, 6);
                let detalle_horario = {
                id: 0,
                dia: this.dia,
                hora_entrada: this.hora_entrada,
                hora_salida: this.hora_salida,
                };
                this.horario.detalles_horario.push(detalle_horario);
                let ordenaDetalle_horario = [];
                for (var i = 0; i < this.semanaOriginal.length; i++) {
                for (var j = 0; j < this.horario.detalles_horario.length; j++) {
                    if (
                    this.semanaOriginal[i] === this.horario.detalles_horario[j].dia
                    ) {
                    ordenaDetalle_horario.push(this.horario.detalles_horario[j]);
                    }
                }
                }
                this.horario.detalles_horario = ordenaDetalle_horario;
                let index = this.semana.indexOf(this.dia);
                this.semana.splice(index, 1);
                this.dia = "";
                this.hora_entrada = "";
                this.hora_salida = "";
            }
            }
        },
        eliminaHorario2(index,id){
            if(id != 0){
            let id_dia = {"eliminado_id":id};
            this.horario.detalles_eliminados.push(id_dia);
            }
            let dia=this.horario.detalles_horario[index].dia;
            this.semanaHorarios.forEach(el => {
            if(dia == el.dia){
                el.desabilitar=false;
            }
            })
            this.horario.detalles_horario.splice(index,1);
        },
        getTipoJornada(){
            let parametros = {activo:true, paginate:false};
            apiTipoJornada.find(parametros).then((response) => {
                this.jornadas = response.data;
            })
            .catch(err =>{
                this.respuesta_error(err);
            })
        },        
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.detectarNavegador();
        this.resetValues();
        this.getTipoJornada();
        this.loadData();
    } 
}
</script>

<style scoped>
    .displaySemana{
        display: contents !important;
    }
    .centrar{
        height: 90px !important;
        width: 500px !important;
        margin: auto !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
    }
    .dias{
        height: 35px;
    }
    .detalle {
      margin-top: -20px !important;
    }
    .displayTemplate{
        display: contents;
    }
</style>