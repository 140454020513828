<template>
  <v-app>
    <v-content class="align-center justify-center">
      <v-container fluid grid-list-xl class="ma-0 pa-0">
        <v-row wrap class="fondo-configuracion ma-0 pa-0">    
          <v-col xs="12" sm="5" md="4" lg="4" xl="4" class="align-center d-flex justify-center">
            <div class="center-configuracion align-center justify-center">
                <v-row class="align-center justify-center"  >
                    <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                        <v-img src="/static/bg/logo_cliker.svg" alt="Logo Cliker" class="mb-3 ml-n2" width="100"></v-img>
                        <p class="subtitulo-configuracion"></p>
                        <p class="division-pregunta"></p>
                        <p class="titulo-configuracion">
                            Bienvenido
                        </p>
                        <p class="texto-configuracion">
                          Antes de comenzar, realizaremos un par de pasos para configurar tu sistema.
                        </p>
                        <p class="division-pregunta"></p>
                        <p class="mt-4">
                            <v-card-actions class="px-0 py-2">
                                <button class="btnOk" @click="comportamiento()" >Siguiente</button>
                            </v-card-actions>
                        </p>
                    </v-col>
                </v-row>
            </div>
          </v-col>
          <v-col xs="12" sm="7" md="8" lg="8" xl="8" class="col-imagen-configuracion">
            <div class="contenedor-img">
                <img src="/static/wizard/bienvenida.png" class="img-pic">
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import usuarios from '../api/usuarios';
import configuracionInicial from '../api/configuraciones';
import clienteApi from "../api/clientes";
import Swal from "sweetalert2"
import Notify from "@/plugins/notify";

export default {
  data(){
    return{
      dialog:null,
      titulo_modal:null,
      descripcion:null,
      nombre_archivo:null,
      loading_guardar:false,
      imageUrl:null,
      pasouno:{        
        cliente_id:null,
        empresa_id:null,
        tipo:1,
        archivo:null,
        valor:0,
        rol_name:null
      },
      datosLogin:null,
      rol:null,
      image:null,
    }
  },
  methods:{
    comportamiento(){        
        this.$router.push('/paso_uno');       
      },
    abrirModal(){
      this.pasouno.archivo = null;
      this.nombre_archivo = "";
      this.titulo_modal = "Subir logo";
      
      if(this.rol == 'admin'){
        this.pasouno.cliente_id = this.datosLogin.cliente_id;
      }
      
      if(this.rol == 'admin-empresa'){
        this.pasouno.empresa_id = this.datosLogin.cliente_id;
      }
      this.pasouno.tipo = 1;
      this.pasouno.rol_name = this.rol;

      setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
    },
    cerrarModal(){      
      this.dialog = false;
    },
    pickFile(){
      const Input = this.$refs.image;
      Input.type = 'text';
      Input.type = 'file';
      Input.click();
    },
    onFilePicked(e){
      const files = e.target.files

      if(files[0] !== undefined){
        this.nombre_archivo = files[0].name;
        if(this.nombre_archivo.lastIndexOf('.') <= 0){
          return;
        }
        const fr = new FileReader();
        this.image = new Image();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load',() =>{
          this.imageUrl = fr.result;
          this.image = fr.result;
          this.pasouno.archivo = files[0];
        });
      }
      else{
        this.nombre_archivo = null;
        this.imageUrl = null;
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var self = this;
      
      reader.onload = (e) => {
        self.image = e.target.result;
        self.set('img');
      };
      reader.readAsDataURL(file);
    },
    guardar(){
      let self = this;
      let formData = new FormData();
      this.loading_guardar = true;

      if(self.pasouno.archivo !== null){
        let formData = new FormData();
        formData.append("cliente_id", self.pasouno.cliente_id);
        formData.append("archivo", self.pasouno.archivo);
        formData.append("update", false);

        clienteApi
          .updateLogo(formData)
          .then((result) => {
            this.cerrarModal();
            this.$router.push("/paso_dos");
          })
          .catch((err) => {
            this.respuesta_error("Se presento un error al guardar el logo");
          });      
      }
      else{
        Notify.ErrorToast("Sin archivo para subir, selecciona una imagen valida");
      }
      
    },
    respuesta_ok(msj = ""){
      this.cerrarModal();
      Notify.Success("Operación exitosa", msj);
      this.$router.push('/paso_dos');
    },
    respuesta_error(err) {
      this.loading_guardar = false;
      let error = Object.values(err.response.data.error);
      let leyenda = ``;
      for (var i = 0; i < error.length; i++) {
        leyenda += `* ` + error[i] + `\n`;
      }

      Notify.ErrorToast(leyenda);
    },
    respuesta_sin_archivo(){
      Swal.fire({
        position: 'center',
        type: 'error',
        title: "Error",
        text: "Sin archivo para subir, selecciona una imagen valida",
        showConfirmButton: true,
      });
    }

  },
  created(){
    //document.getElementById("inspire").style.marginLeft = "0px";
    //document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px";
    this.$emit('comportamiento',true);  
    
    this.datosLogin = this.$session.get("usuario");
    this.rol = this.datosLogin.rol.name;
    
    if(this.rol == 'root'){
      document.getElementById("inspire").style.marginLeft = "0px";
      document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px";  
    }
  }
}
</script>

<style scoped lang="css">
  .btnContOmitir{
    margin-top: -10%;
  }
  .center-configuracion{
      display: flex;
      width: 100%;      
      vertical-align:middle;
  }

  .contenedor-img{
    width: 100%;
    display: flex;
    margin:0px auto;
    text-align:center;
  }

  .img-pic{
      margin:auto;
      width: 80%;
  }
  .col-imagen-configuracion{
    align-self: flex-end;
    /* align-self: center; */
  }

  .logo{
      font-family: "Montserrat";
      font-size: 36px;
      font-weight: 800;
      text-align: left;
      color:#205154;
  }
  .subtitulo-configuracion{
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #2DB9D1;
  }
  .titulo-configuracion{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    color: #111845;
  }
  .texto-configuracion{
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #111845;
  }
  .btnOk{
    width: 100%;
    height: 52px;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    align-items: center !important;
    text-align: center !important;
    color: #F2F2F2;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #1E2245 !important;
    box-shadow: 5px 10px 24px rgba(0, 0, 0, 0.1), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
    border-radius: 174px;
  }
  .btnOmitir{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
  }
  #no-background-hover::before {
    background-color: transparent !important;
    border-color: transparent!important;
  }
  .division-pregunta{
    width: 30px;
    height: 0px;
    border: 1px solid #E8404E;
  }

  @media screen and (max-width:959px) and (min-width:460px){
    .medidas{
      height:100%
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }

  @media screen and (max-width:1024px) and (min-width:960px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1200px) and (min-width:1025px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1600px) and (min-width:1201px){
    .center-configuracion{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1800px) and (min-width:1601px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:2000px) and (min-width:1801px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  .fondo-configuracion{
    background-color:white !important;
  }


</style>


