<template>
    <div class="card">
        <header class="card-header">
            <ul class="tab-heads">
                <li
                    class="tab-head"
                    v-for="tab in tabs"
                    :key="tab"
                    v-bind:class="{'tab-head--active' : activeTab === tab}"
                    v-on:click="switchTab(tab)">
                    <slot :name="tabHeadSlotName(tab)"> {{ tab }} </slot>
                </li>
            </ul>
        </header>
        <main class="card-body">
            <div class="tab-panel">
                <slot :name="tabPanelSlotName"></slot>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: {
        initialTab: String,
        tabs: Array,
    },
    data() {
        return {
            activeTab: this.initialTab,
        }
    },
    computed: {
        tabPanelSlotName() {
            return `tab-panel-${this.activeTab}`;
        }
    },
    methods: {
        tabHeadSlotName(tabName) {
            return `tab-head-${tabName}`;
        },
        switchTab(tabName) {
            this.activeTab = tabName;
            this.$emit("changeTab", tabName);
        }
    }
}
</script>

<style scoped>
    .card {
        background-color: #EFEFEF;
        /* border-radius: 6px; */
    }

    .card-header {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #C4C4C4;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: transparent;
        padding: 15px 8px 0;
        overflow-x: auto;
    }

    .tab-heads {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: 6px;
        margin-top: -4px;
    }

    li.tab-head {
        padding: 5px 18px;
        position: relative;
        cursor: pointer;
    }
    li.tab-head:hover {
        color: #101C5A;
    }

    li.tab-head--active {
        color: #101C5A;
        transition: 0.4s;

        font-weight: 600;
        background: rgba(13,12,34,0.05);
        border-radius: 8px;
    }

    li.tab-head--active::before {
        transition: 0.4s;
        width: 100px !important;
    }

    .card-body {
        padding-top: 20px;
    }
</style>