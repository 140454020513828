<template>
  <v-app>
    <v-content class="align-center justify-center">
      <v-container fluid grid-list-xl class="ma-0 pa-0">
        <v-row wrap class="fondo-configuracion ma-0 pa-0">    
          <v-col xs="12" sm="5" md="4" lg="4" xl="4" class="align-center d-flex justify-center">
            <div class="center-configuracion align-center justify-center">
                <v-row class="align-center justify-center"  >
                    <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                        <v-img src="/static/bg/logo_cliker.svg" alt="Logo Cliker" class="mb-3 ml-n2" width="100"></v-img>
                        <p v-if="rol == 'admin'" class="subtitulo-configuracion">
                          Configuración 1/3
                        </p>
                        <p v-if="rol == 'admin-empresa'" class="subtitulo-configuracion">
                          Configuración 1/2
                        </p>
                        <p class="division-pregunta"></p>
                        <p class="titulo-configuracion">
                            Puedes agregar un logotipo
                        </p>
                        <p class="division-pregunta"></p>
                        <p class="mt-4">
                            <v-card-actions class="px-0 py-2">
                                <button class="btnOk" @click="comportamiento('next')" >Adjuntar</button>
                            </v-card-actions>
                            <v-card-actions class="px-0 btnContOmitir py-2">
                                <v-btn text color="#939596" width="100%" class="btnOmitir" id="no-background-hover" @click="comportamiento('omitir')">Omitir</v-btn>
                            </v-card-actions>
                        </p>
                    </v-col>
                    <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                      <v-btn text color="#939596" class="btnAnterior ml-0 pl-0 no-background-hover" @click="comportamiento('anterior')">
                        <v-icon left>arrow_back</v-icon>
                        Regresar</v-btn>
                    </v-col>
                </v-row>
            </div>
          </v-col>
          <v-col xs="12" sm="7" md="8" lg="8" xl="8" class="col-imagen-configuracion">
            <div class="contenedor-img">
                <img src="/static/wizard/configuracion_01.png" class="img-pic">
            </div>
          </v-col>
        </v-row>

        <template>
          <v-row class="justify-center">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="titleModal px-5 py-5" v-text="titulo_modal"></span>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-container grid-list-md id="contenedor">
                    <v-row>
                      <!--
                      <v-col xs="12" sm="12" md="12">
                        <h5 v-text="descripcion"></h5>-->
                        <v-col xs="12" sm="12" md="12" class="py-0">
                          <v-text-field 
                            outlined
                            readonly
                            label="logotipo.png" 
                            @click='pickFile' 
                            v-model='nombre_archivo' 
                            
                            append-icon='attach_file'
                          >
                          </v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="image"
                            accept="image/*"
                            @change="onFilePicked"                      
                          >
                        </v-col>
                      <!-- </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-layout row>
                    <v-flex justify-center >
                      <div class="btnModal">
                        <v-btn
                          class="btnGuardar float-right"
                          @click="guardar()"
                          :loading="loading_guardar"
                        >
                          Guardar
                        </v-btn>
                        <button
                          class="btnCerrar float-right"
                          @click="cerrarModal()"
                          :loading="loading_guardar"
                        >
                          Cancelar
                        </button>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template> 
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import usuarios from '../api/usuarios';
import configuracionInicial from '../api/configuraciones';
import clienteApi from "../api/clientes";
import Swal from "sweetalert2"
import Notify from "@/plugins/notify";

export default {
  data(){
    return{
      dialog:null,
      titulo_modal:null,
      descripcion:null,
      nombre_archivo:null,
      loading_guardar:false,
      imageUrl:null,
      pasouno:{        
        cliente_id:null,
        empresa_id:null,
        tipo:1,
        archivo:null,
        valor:0,
        rol_name:null
      },
      datosLogin:null,
      rol:null,
      image:null,
      nextUrl:null,
    }
  },
  methods:{
    comportamiento(accion){
      if(accion == "next"){        
        this.abrirModal();        
      }
      else if(accion == 'anterior'){
        this.$router.push('/bienvenido');
      }
      else if(accion == "omitir"){  
        this.$router.push(this.nextUrl);           
      }
    },
    abrirModal(){
      this.pasouno.archivo = null;
      this.nombre_archivo = "";
      this.titulo_modal = "Sube tu logotipo";
      
      this.pasouno.cliente_id = this.datosLogin.cliente_id;
      
      if(this.rol == 'admin-empresa'){
        this.pasouno.empresa_id = this.datosLogin.empresa_id;
      }
      this.pasouno.tipo = 1;
      this.pasouno.rol_name = this.rol;

      setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
    },
    cerrarModal(){      
      this.dialog = false;
    },
    pickFile(){
      const Input = this.$refs.image;
      Input.type = 'text';
      Input.type = 'file';
      Input.click();
    },
    onFilePicked(e){
      const files = e.target.files

      if(files[0] !== undefined){
        this.nombre_archivo = files[0].name;
        if(this.nombre_archivo.lastIndexOf('.') <= 0){
          return;
        }
        const fr = new FileReader();
        this.image = new Image();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load',() =>{
          this.imageUrl = fr.result;
          this.image = fr.result;
          this.pasouno.archivo = files[0];
        });
      }
      else{
        this.nombre_archivo = null;
        this.imageUrl = null;
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var self = this;
      
      reader.onload = (e) => {
        self.image = e.target.result;
        self.set('img');
      };
      reader.readAsDataURL(file);
    },
    guardar(){
      let self = this;
      let formData = new FormData();
      this.loading_guardar = true;

      if(self.pasouno.archivo !== null){
        let formData = new FormData();

      if(this.rol == 'admin'){
        formData.append("cliente_id", self.pasouno.cliente_id);
        formData.append("archivo", self.pasouno.archivo);
        formData.append("update", false);
      }
      
      if(this.rol == 'admin-empresa'){
        formData.append("cliente_id", self.pasouno.cliente_id);
        formData.append("empresa_id", self.pasouno.empresa_id);
        formData.append("archivo", self.pasouno.archivo);
        formData.append("update", false);
      }
        

        clienteApi
          .updateLogo(formData)
          .then((result) => {
            this.loading_guardar = false;
            this.cerrarModal();
            this.$router.push(this.nextUrl);
          })
          .catch((err) => {
            this.loading_guardar = false;
            this.respuesta_error("Se presento un error al guardar el logo");
          });      
      }
      else{
        this.loading_guardar = false;
        Notify.ErrorToast("Sin archivo para subir, selecciona una imagen valida");
      }
      
    },
    respuesta_ok(msj = ""){
      this.cerrarModal();
      Notify.Success("Operación exitosa", msj);
      this.$router.push(this.nextUrl);
    },
    respuesta_error(err) {
      this.loading_guardar = false;
      let error = Object.values(err.response.data.error);
      let leyenda = ``;
      for (var i = 0; i < error.length; i++) {
        leyenda += `* ` + error[i] + `\n`;
      }

      Notify.ErrorToast(leyenda);
    },
    respuesta_sin_archivo(){
      Swal.fire({
        position: 'center',
        type: 'error',
        title: "Error",
        text: "Sin archivo para subir, selecciona una imagen valida",
        showConfirmButton: true,
      });
    }

  },
  created(){
    //document.getElementById("inspire").style.marginLeft = "0px";
    //document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px";
    this.$emit('comportamiento',true);  
    
    this.datosLogin = this.$session.get("usuario");
    this.rol = this.datosLogin.rol.name;

    if(this.rol == 'admin'){
      this.nextUrl = "/paso_dos";
    }else if(this.rol == 'admin-empresa'){
      this.nextUrl = "/paso_tres"
    }
    
    if(this.rol == 'root'){
      document.getElementById("inspire").style.marginLeft = "0px";
      document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px";  
    }
  }
}
</script>

<style scoped lang="css">
  .titleModal{
    font-family: "Montserrat";
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: #1E2245 !important;
  }
  .btnContOmitir{
    /*margin-top: -10%;*/
  }
  .center-configuracion{
      display: flex;
      width: 100%;      
      vertical-align:middle;
  }

  .contenedor-img{
    width: 100%;
    display: flex;
    margin:0px auto;
    text-align:center;
  }

  .img-pic{
      margin:auto;
      width: 80%;
  }
  .col-imagen-configuracion{
    align-self: flex-end;
    /* align-self: center; */
  }

  .logo{
      font-family: "Montserrat";
      font-size: 36px;
      font-weight: 800;
      text-align: left;
      color:#205154;
  }
  .subtitulo-configuracion{
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #2DB9D1;
  }
  .titulo-configuracion{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    color: #111845;
  }
  .texto-configuracion{
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #111845;
  }
  .btnOk{
    width: 100%;
    height: 52px;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    align-items: center !important;
    text-align: center !important;
    color: #F2F2F2;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #1E2245 !important;
    box-shadow: 5px 10px 24px rgba(0, 0, 0, 0.1), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
    border-radius: 174px;
  }
  .btnOmitir{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
  }
  #no-background-hover::before {
    background-color: transparent !important;
    border-color: transparent!important;
  }
  .division-pregunta{
    width: 30px;
    height: 0px;
    border: 1px solid #E8404E;
  }
  .btnAnterior{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
    margin-top: 20%;
    border-color: transparent!important;
  }

  @media screen and (max-width:959px) and (min-width:460px){
    .medidas{
      height:100%
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }

  @media screen and (max-width:1024px) and (min-width:960px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1200px) and (min-width:1025px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1600px) and (min-width:1201px){
    .center-configuracion{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1800px) and (min-width:1601px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:2000px) and (min-width:1801px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  .fondo-configuracion{
    background-color:white !important;
  }



</style>


