<template>
    <div class="notification-panel">
    
    <template v-for="(item, index) in notifications" >
      <div :key="index + '-list'" @click="handleClick(item)">
        <div :class="['notification-item py-5',selected == item.id?'active':'']">
          <div class="circle mr-3" :style="'background:'+getColorNotification()">
            <p >{{item.nombre_emisor?item.nombre_emisor.charAt(0):"C"}}</p>
          </div>
          <v-row>
            <v-col cols="12" class="py-0 pb-1">
              <p :class="['title mb-0',item.activo?'notRead':'']">{{item.nombre_emisor}}</p>
            </v-col>
            <v-col cols="12" class="py-0 pb-1">
              <p class="subtitle my-0" > {{item.descripcion }}</p>
            </v-col>
            <transition name="slide">
               <v-col cols="12" class="pt-4 pb-0" v-if="selected == item.id">
                <p class="subtitle my-1">Tipo: {{item.modulo}}</p>
                <p class="subtitle my-1">Origen: {{item.origen}}</p>
                <p class="subtitle my-1">Fecha de inicio: {{dateFormat(item.inicio_incidencia)}}</p>
                <p class="subtitle my-1">Fecha final: {{dateFormat(item.final_incidencia)}}</p>
                <p class="subtitle my-1">Duración: {{dateDiference(item.inicio_incidencia,item.final_incidencia)}}</p>
              </v-col>
            </transition>
            <v-col cols="12" class="py-0">
              <p class="time my-0 ">{{ timeAgo(item.created_at) }}</p>
            </v-col>
          </v-row>
            <span :class="['badge', !item.activo?'grey':'']"></span>
            <v-menu right v-if="item.activo">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small 
                        icon 
                        class="ml-5 no-background-hover"
                        v-bind="attrs"
                        v-on="on"
                        :ripple="false"
                    >
                        <v-icon>more_horizo</v-icon>
                    </v-btn>
                </template>

                <v-list >
                    <v-list-item
                        v-for="(map, i) in [{title:'Marcar como leída'}]"
                        :key="i"
                    >
                        <v-list-item-title @click="updateNotification(item)" >{{ map.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </div>

      </div>
      <hr/>
    </template>
    
  </div>
</template>

<script>

//IMPORTS

//APIS
import notificacionesApi from "@/api/notificaciones";

//PLUGINS
import Notify from '@/plugins/notify';
import moment from 'moment';

//CONFIGURATION
moment.locale('es');

export default {

  /*PORPS: variables recibidad del componente padre
           por medio  de una variable tipo prop.*/
  props: {
    //notifications: array[] de objetos notificaciones
    notifications: {
      type: Array,
      default: () => ([]),
    }
  },

  //DATA
  data(){
    return {
      selected : 0,
    }
  }, 

  //METHODS
  methods: {

    //FUNCIONES GENERALES

    /*
    Entrada: data objeto notificacion{}
    Salida: 
    Descripcion: Recive el objeto notificacion para 
                 indentificar el elemento seleccionado 
                 y expandir su información y cerrarlo en 
                 caso de ser el mismo u otro.
    */
    handleClick(data) {
      if(data.id == this.selected){
        this.selected = 0;
      }else{
        this.selected = data.id;
      }
    },

    /*
    Entrada:
    Salida: color cadena con el color en exadecimal
    Descripcion: Retorna un color al azar para el icono de 
                 la notificacion entre las 13 opciones existentes.
    */
    getColorNotification(){
      var color = "#2DB9D1";
      var num = Math.floor(Math.random() * (13 - 1));
      
      switch(num){
        case 1:
          color = "#2DB9D1";
          break;
        case 2:
          color = "#2D9CDB";
          break;
        case 3:
          color = "#636997";
          break;
        case 4:
          color = "#9B51E0";
          break;
        case 5:
          color = "#BB6BD9";
          break;
        case 6:
          color = "#3E4BBA";
          break;
        case 7:
          color = "#1E2245";
          break;
        case 8:
          color = "#E8404E";
          break;
        case 9:
          color = "#F2C94C";
          break;
        case 10:
          color = "#F2994A";
          break;
        case 11:
          color = "#219653";
          break;
        case 12:
          color = "#6FCF97";
          break;
        case 13:
          color = "#04C456";
          break;
      }

      return color;
    },

    /*
    Entrada: value string de fecha
    Salida: string del tiempo transcurrido
    Descripcion: Se utiliza la libreria moment para sacar 
                 la diferencia de tiempo entre la fecha 
                 recibida y hoy.
    */
    timeAgo(value) {
      return moment(value).fromNow();
    },

    /*
    Entrada: data Objeto notificacion{} 
    Salida:
    Descripción: Ejectuta el API readNotificacion para 
                 actualizar la misma y ponerla como leida
                 y actualizando las notificaciones
                 con $emit("updateNotificacions").
    */
    updateNotification(data) {
      notificacionesApi.readNotificacion({notificacion_id : data.id})
        .then((response) => {
          data.activo = false;
          this.$emit("readNotification", data);
        }).catch((error) => {
          Notify.ErrorToast(error.response.data.message);
        });
    },

    /*
    Entrada: fecha String fecha de inicio o fin 
    Salida: fecha2 string fecha con nuevo formato
    Descripción: Separa la fecha recibida por - y remplaza 
                 por / al igual que invirte el orden para 
                 retornar un formato de fecha mas familiar.
    */
    dateFormat(fecha) {
      if(fecha){
        let fecha2 = fecha.split("-");
        return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
      }
      return "--/--/--";
    },

    /*
    Entrada: inicio,fin Strings fecha de inicio y fin 
    Salida: days string de duracion en dias
    Descripción: Recibe fecha inicial y final para sacar 
                 la duración de la incidencia en dias.
    */
    dateDiference(inicio,fin){
      var day1 = new Date(inicio); 
      var day2 = new Date(fin);

      var difference= Math.abs(day2-day1);
      let days = difference/(1000 * 3600 * 24) + 1;

      if(days > 1){
        days = days + " días";
      }else{
        days = days + " día";
      }

      return days;
    }
  },

};
</script>

<style scoped>
  .scroll-enabled {
    overflow: auto;
  }

  .notification-panel {
    background-color: #FFFFFF;
    min-height: 41px;
    max-height: 60vh;
    padding-top: 10px;
  }
  div.notification-item {
    width: 100%;
    background-color: #FFF;
    user-select: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    min-height: 50px;
    max-width: 100%;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #000000;
    text-align: left;

    padding: 44px;
    transition:0.3s;
  }
  div.notification-item:hover {
    background: rgba(174, 178, 211,0.2);
  }

  .active{
    background: rgba(174, 178, 211,0.2) !important;
  }
  div.notification-item div {
    padding-right: 35px;
  }
  .notification-icon {
    padding-right: 12px;
    font-size: 18px;
  }
  .title{
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 125% !important;
    color: #1E2144 !important;
  }
  .notRead{
    font-weight: bold !important;
  }
  .subtitle {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 125% !important;
    color: #828282 !important;
  }
  .time {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #1E2245;
  }
  .badge {
      background-color: #2DB9D1;
      border-radius: 100%;
      width: 12px;
      height: 12px;
  }

  .grey{
    background-color: #BDBDBD;
    margin-right: 48px;
  }

  .circle{
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }

  .circle p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF; 
    width:40px;
    height: 40px;
    padding-left:13px;
    display: flex;
    align-items: center;
  }

  hr{
    border: 0.5px solid #E0E0E0 !important;
  }

  .btnNotification.theme--light.v-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    text-transform: capitalize;
    transition: 0.3s;
    height: 27px !important;
    background: #58C5D8;
    border-radius: 31px;
}

.btnNotification.theme--light.v-btn:hover {
    background: #2DB9D1 !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
	-webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
	-moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
}

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    transition: 0.3s;
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    transition: 0.3s;
    overflow: hidden;
    max-height: 0;
  }

  .v-menu__content{
      border-radius: 8px;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
  }
  .no-background-hover::before {
      background-color: transparent !important;
  }
</style>
