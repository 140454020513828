<template>
  <v-app>
    <template v-if="isSaving">
      <div class="loading">
          <lottie :options="defaultOptions" :height="150" :width="150"/>
      </div>
      <div class="cont-text">
        <h2 class="texto-loading">{{frase}}</h2>
      </div>
    </template>
    <v-content class="align-center justify-center">
      <v-container fluid grid-list-xl class="ma-0 pa-0 ">
        <v-row class="fondo-configuracion ma-0 pa-0 ">    
          <v-col xs="12" sm="5" md="4" lg="4" xl="4" class="align-center d-flex justify-center">
            <div class="center-configuracion align-center justify-center">
                <v-row class="align-center justify-center"  >
                    <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                        <v-img src="/static/bg/logo_cliker.svg" alt="Logo Cliker" class="mb-3 ml-n2" width="100"></v-img>
                        
                        <p v-if="rol == 'admin'" class="subtitulo-configuracion">
                          Configuración 3/3
                        </p>
                        <p v-if="rol == 'admin-empresa'" class="subtitulo-configuracion">
                          Configuración 2/2
                        </p>

                        <p class="division-pregunta"></p>
                        
                        <p v-if="rol == 'admin'" class="titulo-configuracion">
                          Configura tu primera empresa
                        </p>
                        <p v-if="rol == 'admin-empresa'" class="titulo-configuracion">
                          ¿Deseas configurar tus catálogos ahora?
                        </p>

                        <p class="division-pregunta"></p>
                        <p class="mt-4">
                            <v-card-actions class="px-0 py-2">
                                <button class="btnOk" v-if="!datosListos" @click="comportamiento('next')" >Configurar ahora</button>
                                <button class="btnOk" v-else-if="datosEmpresa && rol == 'admin-empresa'" @click="send(datosEmpresa)" >Enviar configuración</button>
                                <button class="btnOk" v-else-if="datosEmpresa && rol == 'admin'" @click="final(empresa)" >Enviar configuración</button>
                            </v-card-actions>
                            <v-card-actions class="px-0 btnContOmitir py-2">
                                <v-btn text color="#939596" width="100%" class="btnOmitir" id="no-background-hover" @click="comportamiento('omitir')">Omitir</v-btn>
                            </v-card-actions>
                        </p>
                    </v-col>
                    <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                      <v-btn text color="#939596" class="btnAnterior ml-0 pl-0 no-background-hover" @click="comportamiento('anterior')">
                        <v-icon left>arrow_back</v-icon>
                        Regresar</v-btn>
                    </v-col>
                </v-row>
            </div>
          </v-col>
          <v-col xs="12" sm="7" md="8" lg="8" xl="8" class="col-imagen-configuracion">
            <div class="contenedor-img">
                <img src="/static/wizard/configuracion_03.png" class="img-pic">
            </div>
          </v-col>
        </v-row>


        <template>
            <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card class="border-card">
                    <perfect-scrollbar> 
                        <v-card-title class="justify-center titulo card_titulo">
                            <v-col lg="12" class="text-center">
                                <img src="/static/icon/add_empresa.png" style="width: 100px; height: 100px;"/>
                            </v-col>
                            <h2 class="title mb-3" v-text="titulo_modal"></h2>
                            
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="!finaliza_carga">
                            <div id="loading">
                                <v-progress-circular
                                indeterminate
                                :size="100"
                                :width="7"
                                color="blue"
                                ></v-progress-circular>
                            </div>
                            </div>
                            <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                            <pop-empresa
                                v-if="
                                rol == 'admin' &&
                                    bEmpresa &&
                                    !bSucursal &&
                                    !bDeptos &&
                                    !bReloj
                                "
                                :oyente="valor"
                                @save="guardar"
                            ></pop-empresa>
                            <pop-sucursal
                                v-if="
                                rol == 'admin-empresa' &&
                                    !bEmpresa &&
                                    bSucursal &&
                                    !bDeptos &&
                                    !bReloj
                                "
                                :oyente="valor"
                                @save="guardar"
                                @oyenteFalse="oyenteFalse"
                            ></pop-sucursal>

                            <pop-departamento
                                v-if="!bEmpresa && !bSucursal && bDeptos && !bReloj"
                                :oyente="valor"
                                @save="guardar"
                            ></pop-departamento>
                            <pop-horario
                                v-if="!bEmpresa && !bSucursal && !bDeptos && bReloj"
                                :oyente="valor"
                                @save="guardar"
                            ></pop-horario>

                            
                            </v-container>
                        </v-card-text>
                    </perfect-scrollbar>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button
                            class="btnCerrar"
                            @click="cerrarModal()"
                            :loading="loading_guardar"
                        >
                        Saltar
                        </button>
                        <button
                            class="btnGuardar"
                            @click="obtener()"
                            :loading="loading_guardar"
                        >
                            <v-container v-if="rol=='admin'">Terminar</v-container>
                            <v-container v-if="rol=='admin-empresa'">{{btnSaveText}}</v-container>
                        </button>
                    </v-card-actions>
                
                </v-card>
            </v-dialog>
        </template>

      </v-container>
    </v-content>
  </v-app>
  
</template>

<script>

import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import PopEmpresa from "@/components/wizard/PopEmpresa.vue";
import PopSucursal from "@/components/wizard/PopSucursal.vue";
import PopDepartamento from "@/components/wizard/PopDepartamento.vue";
import PopHorario from "@/components/wizard/PopHorario.vue";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import horariosApi from "../api/horarios";
import Swal from "sweetalert2";
import configuracionInicial from '../api/configuraciones';
import Notify from "@/plugins/notify";
import {mapActions,mapState} from 'vuex';
import configuracionesApi from "@/api/configuraciones";
export default {
  components: {
    'lottie': Lottie,
    "pop-empresa": PopEmpresa,
    "pop-sucursal": PopSucursal,
    "pop-departamento": PopDepartamento,
    "pop-horario": PopHorario,
  },
  data() {
    return {
      isSaving:false,
      frases:[
        "Estamos cargando la información de tu empresa, gracias por esperar.",
        "Ahora es más fácil calcular la nómina.",
        "Somos una solución práctica para el desarrollo de tu empresa.",
        "Ahora puedes estar más tranquilo, nosotros nos ocupamos de todo.",
        "Tener ordenada la documentación nunca fue más fácil.",
        "¿Necesitas calcular la nómina y cuentas con poco tiempo? Ahorra tiempo con cliker."],
      frase:null,
      interval:null,
      defaultOptions: { animationData: animationData },
      animationSpeed: 20,
      valor: false,
      dialog: null,
      titulo_modal: "",
      rol: null,
      datosLogin: null,
      srcImagen: "/static/modal/empresa.png",
      finaliza_carga: false,
      loading_guardar: false,
      rEmpresa_id: null,
      rSucursal_id: null,
      rDepartamentos_id: null,
      bEmpresa: false,
      bSucursal: false,
      bDeptos: false,
      bReloj: false,
      datosEmpresa: new Object(),
      datosListos : false,
      btnSaveText : "Siguiente",
      empresa:[],
    };
  },
  computed: {
    ...mapState('auth',["user","datos"])
  },
  watch: {
    isSaving(saving) {
      if(saving){
        this.setFrases();
      }else{

      }
    }
  },
  methods: {
    ...mapActions('auth',["updateUserData"]),
    async setFrases(){
      let frases = this.frases;
      this.frase = frases[0];
      
      this.interval = setInterval(() => {
        this.frase = frases[Math.floor(Math.random() * 6) + 1];
        }, 2500);
    },
    comportamiento(accion) {
      if (accion == "next") {
        this.abrirModal();
      } 
      else if(accion == "anterior"){
        if(this.rol == "admin"){
          this.$router.push('/paso_dos');
        }else if(this.rol == "admin-empresa"){
          this.$router.push('/paso_uno');
        }
      }
      else if (accion == "omitir") {
        if (this.rol == "admin-empresa") {
          var datos =  new Object();
          datos.rol_name = this.rol;
          datos.usuario_id = this.datosLogin.id;
          datos.cliente_id = this.datosLogin.cliente_id;
          datos.empresa_id = this.datosLogin.empresa_id;
          this.send(datos);
        }else{
          this.final();
        }
      }
    },
    abrirModal() {
      this.finaliza_carga = true;

      if (this.rol == "admin") {
        this.titulo_modal = "Alta de empresa";
      }

      if (this.rol == "admin-empresa") {
        this.titulo_modal = "Alta sucursal";
      }

      setTimeout(function() {
        document.getElementById("btnModal").click();
      }, 100);
    },
    cerrarModal() {
      this.dialog = false;
    },
    async send(data){
      let self = this;
      this.isSaving = true;
      
      
      configuracionInicial.addConfiguracionInicial(data)
        .then((response) => {
          let session = this.$session.get('usuario');
          let params = {
            cliente_id  : session.cliente_id,
            empresa_id  : session.empresa_id,
          }

          configuracionesApi.initialUpdate(params).then((response) => {
            self.updateUserData({usuario_id:self.user.id,sistema:self.user.sistema});
          })
          .catch((error) => {
            console.log("Error", error);
            Notify.ErrorToast(error);
          });

          

          setTimeout(function(){
            self.$session.set('usuario',self.datos);
            self.$router.push("/dashboard");
          },10000);
            
        }).catch(err => {
          console.log("fallo", err);
          this.isSaving = false;

          this.datosListos = false;
          this.loading_guardar = false;
          this.valor = false;
          this.respuesta_error(err);
        });
    },
    oyenteFalse(){
      this.valor = false;
    },
    obtener() {
      this.valor = true;
    },
    async guardar(valores) {

      if (!valores.formValidate) {
        valores.cliente_id = this.datosLogin.cliente_id;
        if (this.rol == "admin") { 
          this.datosListos = true;
          this.empresa = [];
          this.empresa.push(valores);
          this.cerrarModal();
        }

        if (this.rol == "admin-empresa") {
          switch (valores.status) {
            case "sucursal":
              this.datosEmpresa.sucursal=valores;
              this.respuesta_ok(valores);
              this.datosListos = true;
              break;
            case "depto":
              this.respuesta_ok(valores);
              this.datosEmpresa.departamento=valores;
              break;
            case "horarios":
              this.respuesta_ok(valores);
              this.datosEmpresa.horario=valores;
              break;
          }
        }
      } else {
        this.valor = false;
      }
    },
    final(val = null){
        let formData = new FormData();
        formData.append('rol_name', this.rol);
        formData.append('usuario_id',this.datosLogin.id);
        formData.append('cliente_id', this.datosLogin.cliente_id);

        let empresa = [];

        if(localStorage.vacaciones_acomulables){
          formData.append('vacaciones_acomulables', localStorage.vacaciones_acomulables);
        }
        if(val != null){
          formData.append('empresa', JSON.stringify(val));
        }
        else{
          formData.append('empresa', JSON.stringify(empresa));
        }
        this.send(formData);
    },
    respuesta_ok(sourceValores = null) {
      /* Swal.fire({
        position: "center",
        type: "success",
        title: "Operación exitosa",
        showConfirmButton: true,
      }).then((result) => {
        if (result.value) {
          this.nextPaso(sourceValores);
        }
      }); */
      this.nextPaso(sourceValores);
    },
    respuesta_error(err) {
      this.loading_guardar = false;
      if(typeof err.response.data.error === 'string'){
          Notify.ErrorToast(err.response.data.error);
      }else{
          let error = Object.values(err.response.data.error);
          let leyenda = ``;

          for (var i = 0; i < error.length; i++) {
              leyenda+= `* ` + error[i] + `\n`;
          }

          Notify.ErrorToast(leyenda);
      }
    },
    nextPaso(source = null) {
      this.valor = false;
      if (this.rol == "admin" && source.status == "sucursal") {
        this.$router.push("/dashboard");
      }

      switch (source.status) {
        case "sucursal":
          this.bEmpresa = false;
          this.bSucursal = false;
          this.bDeptos = true;
          this.bReloj = false;
          this.srcImagen = "/static/modal/departamento.png";
          this.titulo_modal = "Alta de departamento";
          break;
        case "depto":
          this.bEmpresa = false;
          this.bSucursal = false;
          this.bDeptos = false;
          this.bReloj = true;
          this.srcImagen = "/static/bg/dia_festivo.png";
          this.titulo_modal = "Registrar horario";
          this.btnSaveText = "Terminar";
          break;
        case "horarios":
          this.cerrarModal();
          break;
        default:
          this.cerrarModal();
          break;
      }
    },
  },
  created() {
    this.datosLogin = this.$session.get("usuario");
    this.rol = this.datosLogin.rol.name;

    if (this.rol == "admin") {
      this.bEmpresa = true;
    }

    if (this.rol == "admin-empresa") {
      this.bSucursal = true;
      this.datosEmpresa.rol_name = this.rol;
      this.datosEmpresa.usuario_id = this.datosLogin.id;
      this.datosEmpresa.cliente_id = this.datosLogin.cliente_id;
      this.datosEmpresa.empresa_id = this.datosLogin.empresa_id;
    }

    this.$emit("comportamiento", true);
    /* document.getElementById("inspire").style.marginLeft = "0px";
    document.getElementsByClassName("v-toolbar")[1].style.paddingLeft = "0px"; */
  },
  mounted () {
    
  },
};
</script>

<style scoped>
/* Absolute Center Spinner */
.loading {
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  justify-content: center;
  display: flex;
  align-items: center;
  transition: ease-in, 1s;
}
.cont-text{
  display: flex;
  justify-content: center;
  align-items: center;
}
.texto-loading{
  position: fixed;
  z-index: 999;
  bottom: 18vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

/* Animation */





/*Estilos del Modal Empresas*/
  .center-configuracion{
      display: flex;
      width: 100%;      
      vertical-align:middle;
  }

  .contenedor-img{
    background: #FFFFFF;
    width: 100%;
    display: flex;
    margin:0px auto;
    text-align:center;
  }

  .img-pic{
      background: #FFFFFF;
      display: flex;
      margin:auto;
      width: 90%;
  }
  .col-imagen-configuracion{
    align-self: flex-end;
    /* align-self: center; */
  }
#padre {
  padding-left: 40%;
}
#loading {
  display: table-cell;
  vertical-align: middle;
}

#RFC {
  text-transform: uppercase !important;
}
.btnContOmitir{
  /*width: 100%;*/
  }
.imagen_empresa {
  text-align: -webkit-center !important;
  text-align: -moz-center !important;
  padding-top: 5%;
}
.title{
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #1E2245 !important;
}
.titulo {
  font-family: "Montserrat" !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #0f3043 !important;
  letter-spacing: -0.68px;
  line-height: 34px;
}
.invertirColor {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.54);
}
.tamIcono {
  height: 24px !important;
  align-self: center !important;
}
.iconoDelgado {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
}
.transparente {
  background-color: transparent;
  padding: 0 0;
}
/*Fin Estilos Modal*/

.titulo_cliker {
  color: #204154;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.82px;
}
.pregunta {
  color: #002438 !important;
  font-family: "Montserrat";
  font-size: 22px !important;
  font-weight: 700;
}
.fondo {
  background-color: #e4edff;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.estiloLetras {
  font-family: "Montserrat";
  font-weight: 400 !important;
  color: #8a8fb4 !important;
  font-size: 13px !important;
  line-height: 16px;
  margin: 0%;
  padding: 0%;
}
.div_contenedor {
  max-width: 80%;
  margin: 20% auto;
  justify-content: center;
  align-items: center;
}
.medidas {
  height: 100%;
}
.subtitulo-configuracion{
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #2DB9D1;
  }
  .titulo-configuracion{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    color: #111845;
  }
  
 .btnOk{
    width: 100%;
    height: 52px;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    align-items: center !important;
    text-align: center !important;
    color: #F2F2F2;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #1E2245 !important;
    box-shadow: 5px 10px 24px rgba(0, 0, 0, 0.1), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
    border-radius: 174px;
  }
  .btnOmitir{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
  }
  .btnAnterior{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
    margin-top: 20%;
    border-color: transparent!important;
  }
  .no-background-hover::after{
    background-color: transparent !important;
    border-color: transparent!important;
  }
  .no-background-hover::before{
    background-color: transparent !important;
    border-color: transparent!important;
  }
  .division-pregunta{
    width: 30px;
    height: 0px;
    border: 1px solid #E8404E;
  }
  .iiiimmm{
    background-size: 100%;
  }
  .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
  }

@media screen and (max-width:959px) and (min-width:460px){
    .medidas{
      height:100%
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }

  @media screen and (max-width:1024px) and (min-width:960px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1200px) and (min-width:1025px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1600px) and (min-width:1201px){
    .center-configuracion{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1800px) and (min-width:1601px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:2000px) and (min-width:1801px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  .fondo-configuracion{
    background-color:white !important;
  }
  
</style>


