<template>
  <v-app>
    <v-content class="align-center justify-center">
      <v-container fluid grid-list-xl class="ma-0 pa-0">
        <v-row wrap class="fondo-configuracion ma-0 pa-0">    
          <v-col cols="12" xs="12" sm="5" md="4" lg="4" xl="4" class="align-center d-flex justify-center">
            <div class="center-configuracion align-center justify-center">
                <v-row class="align-center justify-center"  >
                    <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                        <v-img src="/static/bg/logo_cliker.svg" alt="Logo Cliker" class="mb-3 ml-n2" width="100"></v-img>
                        <p class="subtitulo-configuracion">Configuración 2/3</p>
                        <p class="division-pregunta"></p>
                        <p class="titulo-configuracion">
                            ¿Deseas que las vacaciones sean acumulables?
                        </p>
                        <p class="division-pregunta"></p>

                        <v-container class="sexo py-0" fluid grid-list-xl>
                          <v-row justify-center>
                            <v-radio-group class="ma-0 pa-0" v-model="tipo_vacaciones">
                                <v-radio label="Si" :value="'S'"></v-radio>
                                <v-radio label="No" :value="'N'"></v-radio>
                            </v-radio-group>
                          </v-row>
                        </v-container>
                        <p>
                            <v-card-actions class="px-0 py-2">
                                <button class="btnOk" @click="comportamiento('next')" >Siguiente</button>
                            </v-card-actions>
                        </p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                      <v-btn text color="#939596" class="btnAnterior ml-0 pl-0 no-background-hover" @click="comportamiento('anterior')">
                        <v-icon left>arrow_back</v-icon>
                        Regresar</v-btn>
                    </v-col>
                </v-row>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="7" md="8" lg="8" xl="8" class="col-imagen-configuracion">
            <div class="contenedor-img">
                <img src="/static/wizard/configuracion_02.png" class="img-pic">
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>      
</template>

<script>
import { mapActions, mapState } from 'vuex';
import configuracionInicial from '../api/configuraciones'
import Swal from "sweetalert2";
import Notify from "@/plugins/notify";

export default {
  data(){
    return{
      tipo_vacaciones:"S",
      pasodos:{
        cliente_id:null,
        empresa_id:null,
        tipo:2,
        valor:0,
        rol_name:null
      },
      datosLogin:null,
      rol:null
    }
  },
  methods:{
    comportamiento(accion){
      if(accion == 'next'){
        localStorage.setItem('vacaciones_acomulables', this.tipo_vacaciones);
        this.$router.push('/paso_tres');
      }
      else if(accion == 'anterior'){
        this.$router.push('/paso_uno');
      }
      else if(accion == 'omitir'){
        this.$router.push('/paso_uno');
      }      
    },
  },
  created(){
    this.incidencia = null;
    this.datosLogin = this.$session.get("usuario");
    this.pasodos.rol_name = this.rol = this.datosLogin.rol.name;
    
    if(this.rol == 'admin'){
      this.pasodos.cliente_id = this.datosLogin.cliente_id;
    }
    if(localStorage.vacaciones_acomulables){
      this.tipo_vacaciones = localStorage.vacaciones_acomulables;
    }
    
    if(this.rol == 'admin-empresa'){
      this.pasodos.empresa_id = this.datosLogin.empresa_id;
    }

    //this.$emit('comportamiento',true);
  }
}
</script>

<style scoped>
  .center-configuracion{
      display: flex;
      width: 100%;      
      vertical-align:middle;
  }

  .contenedor-img{
    background: #FFFFFF;
    width: 100%;
    display: flex;
    margin:0px auto;
    text-align:center;
  }

  .img-pic{
      background: #FFFFFF;
      display: flex;
      margin:auto;
      width: 90%;
  }
  .col-imagen-configuracion{
    align-self: flex-end;
    /* align-self: center; */
  }
  .estiloLetras{
    font-family: 'Montserrat';
    font-weight: 400 !important;
    color: #8A8FB4 !important;
    font-size: 13px !important;
    line-height: 16px;
    margin: 0%;
    padding: 0%;
  }
  .subtitulo-configuracion{
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #2DB9D1;
  }
  .titulo-configuracion{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    color: #111845;
  }
 .btnOk{
    width: 100%;
    height: 52px;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    align-items: center !important;
    text-align: center !important;
    color: #F2F2F2;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #1E2245 !important;
    box-shadow: 5px 10px 24px rgba(0, 0, 0, 0.1), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
    border-radius: 174px;
  }
  .btnOmitir{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596 !important;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
  }
  .btnAnterior{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #939596;
    background: transparent !important;
    border-radius: 174px;
    text-transform: none;
    margin-top: 20%;
    border-color: transparent!important;
  }
  .no-background-hover::after{
    background-color: transparent !important;
    border-color: transparent!important;
  }
  .no-background-hover::before{
    background-color: transparent !important;
    border-color: transparent!important;
  }
 .division-pregunta{
    width: 30px;
    height: 0px;
    border: 1px solid #E8404E;
  }
 @media screen and (max-width:959px) and (min-width:460px){
    .medidas{
      height:100%
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }

  @media screen and (max-width:1024px) and (min-width:960px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1200px) and (min-width:1025px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1600px) and (min-width:1201px){
    .center-configuracion{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:1800px) and (min-width:1601px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  @media screen and (max-width:2000px) and (min-width:1801px){
    .medidas{
      height:100%;
    }
    .center-configuracion{
      display: flex;
      width: 100%;      
    }
  }
  .fondo-configuracion{
    background-color:white !important;
  }
</style>

