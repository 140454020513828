
<template>
    <v-container xl fluid>
        <v-row>
            <v-col cols="12" lg="12" class="center">
                <div class="margen d-flex">
                    <hr class="vr"/>
                    <p class="tblName">Notificaciones</p>
                </div>
                <v-row class="mt-5">
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pt-0">
                        <div :class="['notification-card2 mb-8 mt-3',notificationType == 1 ? 'active':'']" @click="notificationType=1">
                            <v-row>
                                <v-col cols="4" class="pa-0 ml-2">
                                    <img src="static/icon/notificaciones/solicitudes.png" class="col-lg-12">
                                </v-col>
                                <v-col cols="4" class="middle pl-0">
                                    <h2 class="titleTipo">
                                        Solicitudes<br>
                                        de tiempo y 
                                        eventos
                                    </h2>
                                </v-col>
                                <v-col cols="3" class="middle text-center">
                                    <p class="totalTipo">
                                        Total <br><span class="totalNumberTipo">27</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                        <div :class="['notification-card2 mb-8',notificationType == 2 ? 'active':'']" @click="notificationType=2">
                            <v-row>
                                <v-col cols="4" class="pa-0 ml-2"> 
                                    <img src="static/icon/notificaciones/contrato.png" class="col-lg-12">
                                </v-col>
                                <v-col cols="4" class="middle pl-0">
                                    <h2 class="titleTipo">
                                        Documentos
                                    </h2>
                                </v-col>
                                <v-col cols="3" class="middle text-center">
                                    <p class="totalTipo">
                                        Total <br><span class="totalNumberTipo">27</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                        <div :class="['notification-card2 mb-8',notificationType == 3 ? 'active':'']" @click="notificationType=3">
                            <v-row>
                                <v-col cols="4" class="pa-0 ml-2">
                                    <img src="static/icon/notificaciones/pagos.png" class="col-lg-12">
                                </v-col>
                                <v-col cols="4" class="middle pl-0">
                                    <h2 class="titleTipo">
                                        Pagos
                                    </h2>
                                </v-col>
                                <v-col cols="3" class="middle text-center">
                                    <p class="totalTipo">
                                        Total <br><span class="totalNumberTipo">27</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                        <div :class="['notification-card2 mb-8',notificationType == 4 ? 'active':'']" @click="notificationType=4">
                            <v-row>
                                <v-col cols="4" class="pa-0 ml-2">
                                    <img src="static/icon/notificaciones/incapacidad.png" class="col-lg-12">
                                </v-col>
                                <v-col cols="4" class="middle pl-0">
                                    <h2 class="titleTipo">
                                        Salud
                                    </h2>
                                </v-col>
                                <v-col cols="3" class="middle text-center">
                                    <p class="totalTipo">
                                        Total <br><span class="totalNumberTipo">27</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                        <div class="notification-card">
                            <div class="notification-container row">
                                <div class="notification-header col-lg-12 d-lg-flex ">
                                    <div class="mr-auto mb-5">
                                        <span class="mr-4">Listado de notificaciones</span> <span class="badge">{{notRead}}</span>
                                    </div>


                                    <div class="">
                                        <v-menu buttom rounded offset-y class="elevation-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="white"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    class=" btnMenu btnSelectMenu elevation-0 mr-4 justify-space-between mb-3"
                                                >
                                                {{filterEstatusTitle}}
                                                <v-icon>keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in filterEstatus"
                                                    :key="index"
                                                    @click="opcionEstatus(item,1)"
                                                >   
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-menu buttom rounded offset-y class=" elevation-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="white"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    class=" btnMenu btnSelectMenu elevation-0 mr-4 justify-space-between mb-3"
                                                >
                                                {{filterIncidenciaTitle}}
                                                <v-icon :size="filterIncidenciaTitle != 'Tipo de incidencia'?'18':''" class="ml-2" @click="quitarFiltro(2)">{{filterIncidenciaTitle != "Tipo de incidencia"?"close":"keyboard_arrow_down"}}</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in filterIncidencias"
                                                    :key="index"
                                                    @click="opcionEstatus(item,2)"
                                                >   
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-menu buttom rounded offset-y class=" elevation-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="white"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    class=" btnMenu btnSelectMenu elevation-0 mr-4 justify-space-between mb-3"
                                                >
                                                {{filterMesTitle}}
                                                <v-icon :size="filterMesTitle != 'Mes'?'18':''" class="ml-2" @click="quitarFiltro(3)">{{filterMesTitle != "Mes"?"close":"keyboard_arrow_down"}}</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in filterMes"
                                                    :key="index"
                                                    @click="opcionEstatus(item,3)"
                                                >   
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-menu buttom rounded offset-y class="elevation-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="white"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    class=" btnMenu btnSelectMenu elevation-0 mr-4 justify-space-between mb-3"
                                                >
                                                {{filterAñoTitle}}
                                                <v-icon>keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in filterAño"
                                                    :key="index"
                                                    @click="opcionEstatus(item,4)"
                                                >   
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        
                                        
                                    </div>
                                    
                                </div>
                                <div class="ma-0 ml-10 mb-3">
                                    <v-btn 
                                        class="btn1E2245"
                                        @click="updateNotification"
                                    >
                                        Marcar todo como leídos
                                    </v-btn>
                                </div>
                                <div class="previous col-lg-12 " v-if="notifications.length > 0">
                                    <perfect-scrollbar @ps-y-reach-end="reload">
                                    <notification-list
                                        :notifications="notifications"
                                        @readNotification="loadNotificaciones(true)"
                                    />
                                    <div class="col-lg-12 text-center" v-if="isLoading">Cargando...</div>
                                    </perfect-scrollbar>
                                </div>
                                <div class="noInfo " v-else>
                                    Cuando el equipo empiece a tener alguna <br> actividad, te la mostraremos aquí.
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
         
       <!-- <v-layout row wrap>
            <v-flex md12 sm12>
                
            </v-flex>
            <v-flex md3 sm3>
                <div class="notification-card2">
                    <div class="notification-container">
                        <div class="notification-header">
                            <i class="material-icons">more_horizo</i>
                            <span>Notificaciones</span>
                        </div>
                    </div>
                </div>
            </v-flex>
            <v-flex md3 sm3>
                <div class="notification-card2">
                    <div class="notification-container">
                        <div class="notification-header">
                            <i class="material-icons">more_horizo</i>
                            <span>Notificaciones</span>
                        </div>
                    </div>
                </div>
            </v-flex>
            <v-flex md3 sm3>
                <div class="notification-card2">
                    <div class="notification-container">
                        <div class="notification-headerfind
                    </div>
                </div>
            </v-flex>
            

            <v-flex md8 sm8>
                <div class="notification-card">
                    <div class="notification-container details">
                        <div class="notification-header">
                            <span>{{ notification.title }}</span>
                        </div>

                        <div class="notification-body">
                            {{ notification.description}}
                        </div>
                    </div>
                </div>
            </v-flex>
        </v-layout>-->
    </v-container>
</template>

<script>

//IMPORTS

//APIS
import notificacionesApi from "@/api/notificaciones";

//COMPONENTS
import NotificationList from "@/components/widgets/list/NotificationListCatalog";

//PLUGINS
import Notify from '@/plugins/notify';
import moment from 'moment';

//CONFIGURATION
moment.locale('es');

export default {
    components: {
        NotificationList,
    },
    data() {
        return {
            id_usuario  : null,
            datosLogin  : null,
            notRead     : null,
            lastPage    :null,

            isLoading   : true,
            ready       : false,

            filterEstatusTitle      : "Todos",
            filterIncidenciaTitle   : "",
            filterMesTitle          : "",
            filterAñoTitle          : "",
            
            notificationType    : 1,

            notifications       : [],
            filterIncidencias   : [],
            filterAño           : [],
            filterEstatus       : [
                {id:1, nombre:"Todos",valor:0},
                {id:2, nombre:"Leidos",valor:1}, 
                {id:3, nombre:"No leidos", valor:2}
            ],
            filterMes           : [
                {id:1, nombre:"Enero",valor:"01"},
                {id:2, nombre:"Febrero",valor:"02"}, 
                {id:3, nombre:"Marzo", valor:"03"},
                {id:4, nombre:"Abril", valor:"04"},
                {id:5, nombre:"Mayo", valor:"05"},
                {id:6, nombre:"Junio", valor:"06"},
                {id:7, nombre:"Julio", valor:"07"},
                {id:8, nombre:"Agosto", valor:"08"},
                {id:9, nombre:"Septiembre", valor:"09"},
                {id:10, nombre:"Octubre", valor:"10"},
                {id:11, nombre:"Noviembre", valor:"11"},
                {id:12, nombre:"Diciembre", valor:"12"},
            ],

            notification    : {
                title: "",
                description: "",
                date: "",
                module: ""
            },
            filters         :{
                paginate: true,
                page : 1,
                rol_name: null,
                usuario_id: null,
                estatus_id : 0,
                tipo_id : null,
                mes : null,
                anio : null
            },
            
        }
    },

    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Vulve a cargar las notificaciones 
          apenas un filtro cambie*/
        filters:{
            deep: true,
            handler(newValue, oldValue) {
                if(this.ready){
                    this.filters.page = 1;   
                    this.loadNotificaciones(true);
                }
            }
        }
    },
    methods: {

        //FUNCIONES GENERALES
        
        /*
        Entrada: 
        Salida: 
        Descripcion: Recarga la lista de notificaciones paginandola 
                     cuando se llega al final con el perfect scrollbar.
        */
        reload(){
            if(this.notifications.length > 9){
                if(this.lastPage >= this.filters.page){
                    this.ready = false;
                    this.filters.page++;            
                    this.loadNotificaciones(false);
                }
            }
        },
        
        /*
        Entrada: (opcion,filtro) (objeto seleccionado opcion{},int indicador del filtro)
        Salida: 
        Descripcion: Cambia el la opcion seleccionada de alguno
                     de los filtros identificado por el filtro.
        */
        opcionEstatus(opcion,filtro){

            switch(filtro){
                case 1:
                    this.filters.estatus_id = opcion.valor;
                    this.filterEstatusTitle = opcion.nombre;
                    break;
                case 2:
                    this.filters.tipo_id = opcion.valor;
                    this.filterIncidenciaTitle = opcion.nombre;
                    break;
                case 3:
                    this.filters.mes = opcion.valor;
                    this.filterMesTitle = opcion.nombre;
                    break;
                case 4:
                    this.filters.anio = opcion.valor;
                    this.filterAñoTitle = opcion.nombre;
                    break;
            }
        },

        /*
        Entrada: filtro int indicador del filtro
        Salida: 
        Descripcion: Reinicia el filtro seleccionado a valores nullos.
        */
        quitarFiltro(filtro){

            switch(filtro){
                case 2:
                    this.filters.tipo_id = null;
                    this.filterIncidenciaTitle = "Tipo de incidencia";
                    break;
                case 3:
                    this.filters.mes = null;
                    this.filterMesTitle = "Mes";
                    break;
            }
        },

        //FUNCIONES ESPECIFICAS
        
        /*
        Entrada: 
        Salida: 
        Descripcion: Ejecuta el API getIncidenciasNotificaciones 
                     para traer los tipos de incidencia para el 
                     filtro de tipo de incidencia.
        */
        getIncidencias(){
            let params = {usuario_id: this.datosLogin.id};
            
            notificacionesApi.getIncidenciasNotificaciones(params)
                .then((response) => {
                    //console.log(response);

                    this.filterIncidencias.push({id:0, nombre:"Cumpleaños",valor:0})

                    for(let incidencia of response.data){
                        this.filterIncidencias.unshift({id:incidencia.id,nombre:incidencia.nombre,valor:incidencia.id});
                    }
                })
                .catch((error)=>{
                    Notify.ErrorToast(error.response.data.error);
                });

        },

        /*
        Entrada: filter bolean true para traer desde la primera pagina y false para agregar a la lista
        Salida: 
        Descripcion: Ejecuta el API getNotificaciones 
                     para traer y pintar las notificaciones segun los filtros.
        */
        async loadNotificaciones(filter) {
            this.isLoading=true;
            //console.log(this.filters);
            await notificacionesApi.getNotificaciones(this.filters)
                .then((response) => {
                    if(filter){
                        //console.log("filt")
                        this.notifications = response.data;
                    }else{
                        //console.log("add")
                        for(let item of response.data){
                            //console.log(item);
                            this.notifications.push(item);
                        }
                    }
                    
                    this.notRead = response.no_leido;
                    //console.log(response);
                    this.lastPage = response.last_page;
                    //console.log(this.notifications);
                    this.isLoading  = false;
                    this.ready = true;
                }).catch((error) => {
                    this.isLoading = false;
                    Notify.ErrorToast(error.response.data.error);
                });
        },

        updateNotification() {
            notificacionesApi.readNotificacion()
                .then((response) => {
                    this.loadNotificaciones(true)
                }).catch((error) => {
                    Notify.ErrorToast(error.response.data.message);
                });
        },

    },
    //CREATED
    async created() {
        this.datosLogin = this.$session.get('usuario');
    },
    //MOUNTED
    async mounted () {
        this.id_usuario     = this.datosLogin.id;

        this.getIncidencias();

        let fecha = new Date();
        let mesActual = fecha.getMonth() + 1;
        let añoActual = fecha.getFullYear();

        if(mesActual < 9)
            mesActual = "0" + mesActual;

        for(let i = 2019;i<=añoActual;i++){
            //console.log(i);
            this.filterAño.unshift({nombre:i,valor:i});
        }

        this.filterIncidenciaTitle = "Tipo de incidencia";
        for(let mes of this.filterMes){
            if(mes.valor == mesActual){
                this.filterMesTitle = mes.nombre;
            }
        }
        this.filterAñoTitle = añoActual;

        this.filters.rol_name = this.datosLogin.rol.name,
        this.filters.usuario_id = this.datosLogin.id,
        this.filters.estatus_id = 0;
        this.filters.tipo_id = null;
        this.filters.mes = mesActual;
        this.filters.anio = añoActual;

        //console.log(this.filters);
        await this.loadNotificaciones(true);
        
    }
}
</script>

<style scoped>
    .notification-card {
        background-color: #FFF;
        min-width: 100px;
        min-height: 569px;
        max-height: 100%;
        border-radius: 10px;
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
    }
    .notification-card2 {
        background-color: #FFF;
        min-width: 100px;
        transition: 0.3s;
        border-radius: 10px;
        cursor:pointer;
    }
    .notification-card2:hover{
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    }

    .active{
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
        margin-left:10px;
    }
    .notification-container {
        padding: 50px 0px 0px 0px;
    }
    .notification-container.details {
        padding: 0px;
    }
    .notification-container.details .notification-header {
        padding: 0px 0px;
        border-bottom: 1px solid #C4C4C4;
    }
    .notification-container.details .notification-header span {
        font-weight: 600;
        color: #004BAF;
        margin-top:
    }
    .notification-container.details .notification-body {
        padding: 35px 0px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: #96999A;
    }

    .notification-container .notification-header,
    .notification-container .view-all {
        padding-left: 44px;
        padding-right: 44px;
    }
    .notification-header span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #1E2245;
    }
    .notification-header i {
        float: right;
    }
    .notification-container .new span,
    .notification-container .previous span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        padding-left: 44px;
    }
    .notification-container .new {
        padding-top: 10px;
    }
    .margen {
        height: 48px;
        margin-bottom: 10px;
        margin-left: 1px;
    }
    .center{
        padding-left:32px;
        padding-right:32px;
        margin-left: auto;
        margin-right: auto;
    }

    .middle{
        display: flex;
        align-items: center;
    }

    .titleTipo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #444975;
    }

    .totalTipo{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #444975;
        margin-right: auto;
        margin-left: auto;
    }

    .totalNumberTipo{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #444975;
    }

    .btnSelectMenu {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* padding-right: 10px !important; */
        padding: 0px 8px 0px 13px !important;
        color: #444975;
        text-transform: none;
        margin-top:-8px;
        height: 38px !important;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
    }


    .v-menu__content{
        border-radius: 15px;
        margin-top: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .noInfo{
        background: rgba(174, 178, 211,0.2);
        height: 455px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        margin-left: 12px;
        margin-right: 12px;
        margin-top: 12px
    }

    .badge {
        background-color: #5DD3E8;
        border-radius: 100%;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #FFFFFF !important;
        padding: 3px 8px;
        height: 17px;
    }
</style>                    
