<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col lg="12" class="pl-0">
          <template v-if="isLoading">
            <div id="loading">
              <!-- <pulse-loader
                :loading="isLoading"
                :color="'#004BAF'"
                :margin="'5px'"
                :radius="'30px'"
                :size="'50px'"
              ></pulse-loader> -->
              <SkBuscador />
            </div>
          </template>

          <template v-else>
            <TabCard :tabs="tabs" :initialTab="initialTab" @changeTab="validateTab">
              <template slot="tab-head-empleados">Empleados</template>
              <template slot="tab-panel-empleados">
                <TableBuscador :tab="'empleados'" :rol="rol" :rows="items" :columns="headers" />
              </template>

              <template slot="tab-head-diasFestivos">Días festivos</template>
              <template slot="tab-panel-diasFestivos">
                <TableBuscador :tab="'diasFestivos'" :rol="rol" :rows="items" :columns="headers" />
              </template>

              <template slot="tab-head-horarios">Horarios</template>
              <template slot="tab-panel-horarios">
                <TableBuscador :tab="'horarios'" :rol="rol" :rows="items" :columns="headers" />
              </template>

              <template slot="tab-head-incidencias">Incidencias</template>
              <template slot="tab-panel-incidencias">
                <TableBuscador :tab="'incidencias'" :rol="rol" :rows="items" :columns="headers" />
              </template>
            </TabCard>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TabCard from "@/components/TabCard";
import TableBuscador from "@/components/TableBuscador";
import busquedaApi from "../api/busqueda";
import Notify from '@/plugins/notify';
import SkBuscador from "@/components/skeletons/SkBuscador.vue";

export default {
  components: {
      TabCard,
      TableBuscador,
      SkBuscador,
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      session   : null,
      initialTab: 'empleados',
      tabs      : ['empleados', 'diasFestivos', 'horarios', 'incidencias'],
      rol       : "",
      empleado_id : null,
      isLoading : true,
      headers: [
          {label: "Clave",            name: "clave"                 },
          {label: "Nombre Completo",  name: "nombre_completo"       },
          {label: "Antigüedad",       name: "dia_descanso",         },
          {label: "Ranking",          name: "",                     align: 'center'},
          {label: "Departamento",     name: "nombre_departamento"   },
        ],
      items: [],
      empleados : {
        items:[]
      },
      diasFestivos : {
        items:[]
      },
      horarios : {
        items: []
      },
      incidencias : {
        items: []
      },
    }
  },
  watch: {
    search: function() {
      this.isLoading = true;
      this.generarBusqueda();
    }
  },
  methods: {
    init() {
      if(this.search) {
        this.session      = this.$session.get('usuario');
        this.rol          = this.session.rol.name

        if(this.rol == "empleado") {
          this.tabs         = ['empleados', 'incidencias'];
          this.empleado_id  = this.$session.get('empleado').id;
        } else if(this.rol == "admin-depto") {
          this.tabs         = ['empleados', 'diasFestivos', 'incidencias'];
        }

        this.generarBusqueda();
      }
    },
    generarBusqueda() {
      let params = {
        cliente_id  : this.session.cliente_id,
        empresa_id  : this.session.empresa_id,
        sucursal_id : this.session.sucursal_id,
        empleado_id : this.empleado_id,
        rol_name    : this.rol,
        usuario_id  : this.session.id,
        search      : this.search
      };

      busquedaApi.getBusqueda(params)
        .then((response) => {
          this.isLoading = false;
          this.items = this.empleados.items      = response.empleados;
          this.diasFestivos.items   = response.diasFestivos;
          this.horarios.items       = response.horarios;
          this.incidencias.items    = response.incidencias;
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
          Notify.ErrorToast("Ocurrió un error al realizar la búsqueda, vuelve a intentar.");
        });
    },
    validateTab(tabName) {
      switch(tabName) {
        case "empleados":
          this.headers = [
            {label: "Clave",              name: "clave"               },
            {label: "Nombre Completo",    name: "nombre_completo"     },
            {label: "Antigüedad",         name: "dia_descanso"        },
            {label: "Ranking",            name: "ranking"             , align: 'center'},
            {label: "Departamento",       name: "nombre_departamento" },
          ];
          this.items  = this.empleados.items;
          break;
        case "diasFestivos":
          this.headers = [
            {label: "Nombre",        name: "nombre"       },
            {label: "Festivo",       name: "festivo"      },
            {label: "Fecha",         name: "fecha"        }
          ];

          if(this.rol == "admin-sucursal" || this.rol == "admin-depto")
            this.headers.splice(0,1);

          this.items  = this.diasFestivos.items;
          break;
        case "horarios":
          this.headers = [
            {label: "Nombre",       name: "nombre"          },
            {label: "Descripción",  name: "descripcion"     }
          ];

          if(this.rol == "root") {
            this.headers.unshift(
              { label: 'Empresa', name: 'nombre_empresa'},
            );
          } else if(this.rol == "admin") {
            this.headers.unshift(
              { label: 'Empresa', name: 'nombre_empresa'},
              { label: 'Sucursal', name: 'nombre_sucursal'},
            );
          } else if(this.rol == "admin-empresa") {
            this.headers.unshift(
              { label: 'Sucursal', name: 'nombre_sucursal'}
            );
          } else if(this.rol == "admin-depto") {
            //this.headers.pop();
            this.headers.push(
              { label: 'Minutos de tolerancia', name: 'minutos_tolerancia'}
            );
          }

          this.items  = this.horarios.items;
          break;
        case "incidencias":
          if(this.rol == "empleado") {
            this.headers = [
              {label: "Incidencia",      name: "nombre_tipo"      },
              {label: "Fecha Inicio",    name: "fecha_inicio"     },
              {label: "Fecha Fin",       name: "fecha_final"      },
              {label: "Descripción",     name: "descripcion"      },
              {label: "Estatus",         name: "nombre_estatus"   },
            ];
          } else {
            this.headers = [
              {label:"Nombre Empleado", name:"nombre_completo"  },
              {label:"Incidencia",      name:"nombre_tipo"      },
              {label:"Fecha Inicio",    name:"fecha_inicio"     },
              {label:"Fecha Final",     name:"fecha_final"      },
              {label:"Estatus",         name:"nombre_estatus"   }
            ];
          }
          this.items  = this.incidencias.items;
          break;
      }
    }
  },
  mounted() {
    this.init();
  }  
}
</script>

<style scoped>
  #loading{
    text-align: center !important;
    display: block;
    /* margin-top: 90px; */
  }

  #notificacion{
    top: -13px;
}
</style>