<template>
    <div>
        <div class="buttons-container">
            <div class="button-item">
                <Skeleton width="118px" height="34px" />
            </div>
            <div class="button-item">
                <Skeleton width="118px" height="34px" />
            </div>
            <div class="button-item">
                <Skeleton width="118px" height="34px" />
            </div>
            <div class="button-item">
                <Skeleton width="118px" height="34px" />
            </div>
        </div>

        <div class="table-container">
            <table>
                <tr v-for="i in 5" :key="i">
                    <td><Skeleton width="40px" height="25px" /></td>
                    <td>
                        <div style="display: flex">
                            <Skeleton width="40px" height="40px" rounded />
                            <Skeleton width="300px" height="25px" style="margin-top: 6px; margin-left: 7px;" />
                        </div>
                    </td>
                    <td><Skeleton width="40px" height="25px" /></td>
                    <td><Skeleton width="150px" height="25px" /></td>
                    <td><Skeleton width="200px" height="25px" /></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue';
export default {
    components: {
        Skeleton,
    }
}
</script>

<style scoped>
    .buttons-container {
        display: flex;
    }
    .buttons-container .button-item {
        padding: 0px 10px;
    }
    .table-container {
        box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
        background-color: #FFF;
        border-radius: 20px;
        padding: 10px;
        margin-top: 30px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }

    td {
        background-color: #fdfcfc;
        padding: 23px 0px;
        padding-left: 45px;
    }
</style>